import emelecImg from '../assets/images/emelec.png';
import sicmeImg from '../assets/images/sicme.jpeg';
import titiImg from '../assets/images/completeTiti.jpeg';
import motosanzImg from '../assets/images/motosanz.png';
import jagerImg from '../assets/images/jagerColor.png';
import drustvoImg from '../assets/images/drustvoCom.png';

export const SUPPLIERS = [
  {
    idUser: 'usr-100000',
    uri: 'emelec',
    image: emelecImg,
    userName: 'Emelec SRL',
    primaryColor: '',
    secondaryColor: '',
    textColor: '',
    infoColor: ''
  },
  {
    idUser: 'usr-100003',
    uri: 'sicme',
    image: sicmeImg,
    userName: 'Sicme',
    primaryColor: '',
    secondaryColor: '',
    textColor: '',
    infoColor: ''
  },
  {
    idUser: 'usr-100008',
    uri: 'titi',
    image: titiImg,
    userName: 'Rodamientos Titi',
    primaryColor: '#8ec641',
    secondaryColor: '#C73868',
    textColor: '#48357A',
    infoColor: '#6D4CC7'
  },
  {
    idUser: 'usr-100039',
    uri: 'motosanz',
    image: motosanzImg,
    userName: 'Motosanz',
    primaryColor: '#fd0920',
    secondaryColor: '',
    textColor: '',
    infoColor: '#23B4FC'
  },
  {
    idUser: 'usr-100047',
    uri: 'jager',
    image: jagerImg,
    userName: 'Jager Led',
    primaryColor: '',
    secondaryColor: '',
    textColor: '',
    infoColor: ''
  },
  {
    idUser: 'usr-100048',
    uri: 'drustvo',
    image: drustvoImg,
    userName: 'Drustvo S.R.L.',
    primaryColor: '',
    secondaryColor: '',
    textColor: '',
    infoColor: ''
  }
];
