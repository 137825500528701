import React from 'react';

import { Outlet } from 'react-router-dom';

import Footer from '../components/container/Footer';
import HeaderComponent from '../components/container/Header';

const Layout = () => {
  return (
    <>
      <HeaderComponent />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
