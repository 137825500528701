import React from 'react';
import { useLocation } from 'react-router-dom';

import AliceCarousel from 'react-alice-carousel';

import { CardStarts } from '../../styles/components/StyleCardProduct';
import {
  DetailCardProduct,
  CardInfoDetail
} from '../../styles/components/StyleDetailProduct';

import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

import cartImg from '../../assets/icons/cartButtonWhite.svg';
import whatsappImg from '../../assets/icons/whatsAppButtonWhite.svg';

const DetailProduct = ({
  product,
  getImage,
  getWhatsapp,
  getBranchOffices,
  supplierId,
  handleOpenBranchOffice,
  openBranchOffice
}) => {
  const {
    nombre,
    precio,
    nombree,
    categoria,
    subcategoria,
    cantidad,
    marca,
    descripcion,
    unidad,
    login,
    idusuario
  } = product.product;

  const location = useLocation();
  const { pathname } = location;

  let price = parseFloat(precio);

  let images = product.images || null;
  let features = product.features || null;
  let catalogs = product.catalogs || null;

  // asignamos las imagenes al carrusel
  let itemsCarusel = [];
  if (images.length > 0) {
    itemsCarusel = images.map((img) => (
      <div className="product-img" key={img.idImg}>
        <img src={getImage(img.link)} alt="" />
      </div>
    ));
  }

  return (
    <DetailCardProduct>
      <AliceCarousel mouseTracking items={itemsCarusel} />

      <h2 className="title-card-details">{nombre}</h2>

      <CardStarts supplierId={supplierId}>
        <FaStar className="card-start" />
        <FaStar className="card-start" />
        <FaStar className="card-start" />
        <FaStarHalfAlt className="card-start" />
        <FaRegStar className="card-start" />
      </CardStarts>

      <CardInfoDetail>
        <div className="card-info-price">
          {price === -1 || price === 0 ? (
            <a href="/#" className="card-info-login">
              Iniciar sesión
            </a>
          ) : (
            <>
              <p className="info-price">
                US$<span>350</span>
              </p>
              <p className="info-price">
                Bs<span>{precio}</span>
              </p>
            </>
          )}
        </div>

        <div className="info-distribuidor">
          <p>Distribuidor</p>
          <h4>{nombree}</h4>
        </div>
      </CardInfoDetail>

      <div>
        <h2 className="title-card-details">Detalles del producto:</h2>
        <p className="description-card-details">{descripcion}</p>
      </div>

      <div className="specs-details">
        <h2 className="specs-title">Especificaciones</h2>
        <div className="technical-specs">
          <p className="technical-specs-key">Categoría</p>
          <p className="technical-specs-value">{categoria}</p>
        </div>

        <div className="technical-specs">
          <p className="technical-specs-key">Sub categoria</p>
          <p className="technical-specs-value">{subcategoria}</p>
        </div>

        <div className="technical-specs">
          <p className="technical-specs-key">Marca</p>
          <p className="technical-specs-value">{marca}</p>
        </div>

        <div className="technical-specs">
          <p className="technical-specs-key">Cantidad</p>
          <p className="technical-specs-value">
            {cantidad} {unidad}
          </p>
        </div>

        {features !== null && (
          <>
            <h2 className="specs-title">Medidas</h2>
            {features.map((el) => (
              <div className="technical-specs" key={el.name}>
                <p className="technical-specs-key">{el.name}</p>
                <p className="technical-specs-value">{el.value}</p>
              </div>
            ))}
          </>
        )}

        {catalogs !== null && (
          <>
            <h2 className="specs-title">
              Descargar Catálogo{catalogs.length > 1 ? 's' : ''}
            </h2>
            {catalogs.map((el) => (
              <div className="technical-specs" key={el.idCatalogue}>
                <a
                  href={getImage(el.link)}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={el.link}
                >
                  Descargar
                </a>
              </div>
            ))}
          </>
        )}
      </div>

      <div className="details-buttons">
        <div
          className="btn-branchOffices"
          onClick={() => {
            getBranchOffices(idusuario);
            handleOpenBranchOffice();
          }}
        >
          <span>{openBranchOffice ? 'Cerrar' : 'Sucursales'}</span>
        </div>

        <a
          className="btn-whatsapp"
          href={getWhatsapp(login, nombree, pathname)}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div>
            <img src={whatsappImg} alt="" />
          </div>
          <span>WhatsApp</span>
        </a>

        <div className="btn-buy">
          <div>
            <img src={cartImg} alt="" />
          </div>
          <span>Comprar</span>
        </div>
      </div>
    </DetailCardProduct>
  );
};

export default DetailProduct;
