import React, { useRef } from 'react';

import { createSearchParams, useNavigate } from 'react-router-dom';

import searchIcon from '../../../assets/icons/SearchWhite.svg';
import { StyleForm, SearchTiti } from '../../../styles/components/StyleSearch';

const Search = ({
  searchProducts,
  clearSearch,
  clearErrors,
  subCategory,
  supplier,
  years,
  getBrands,
  brands,
  getModels,
  models,
  clearBrands,
  clearModels
}) => {
  const navigate = useNavigate();

  const searchValue = useRef('');
  const internalValue = useRef('');
  const externalValue = useRef('');
  const heightValue = useRef('');
  const yearValue = useRef('');
  const brandValue = useRef('');
  const modelValue = useRef('');

  const handleChange = (e) => {
    e.preventDefault();
    /* if (brandValue.current.value === 'all') clearBrands();

    if (brandValue.current.value !== 'all')
      getModels(yearValue.current.value, brandValue.current.value); */
    /* if (searchValue.current.value === '') {
      if (supplier !== null) {
        clearSearch();
        getProductsBySupplier(supplier[0].idUser);
      } else {
        clearSearch();
        getProducts();
      }
    } */
  };

  const handleYearChange = (e) => {
    e.preventDefault();
    if (yearValue.current.value === 'Todos') {
      clearBrands();
      clearModels();
    }

    if (yearValue.current.value !== 'Todos') {
      clearBrands();
      clearModels();
      getBrands(yearValue.current.value);
    }
  };

  const handleBrandChange = (e) => {
    e.preventDefault();
    if (brandValue.current.value === 'all') clearModels();
    if (brandValue.current.value !== 'all')
      getModels(yearValue.current.value, brandValue.current.value);
  };

  let idUser = supplier !== null ? supplier[0].idUser : 'all';

  const handleSubmit = (e) => {
    e.preventDefault();

    let query = searchValue.current.value;
    let internal = idUser === 'usr-100008' ? internalValue.current.value : '';
    let external = idUser === 'usr-100008' ? externalValue.current.value : '';
    let height = idUser === 'usr-100008' ? heightValue.current.value : '';
    let year = idUser === 'usr-100008' ? yearValue.current.value : 'Todos';
    let brand = idUser === 'usr-100008' ? brandValue.current.value : 'Todos';
    let model = idUser === 'usr-100008' ? modelValue.current.value : 'Todos';
    let idSubCategory = subCategory;

    searchProducts(
      idUser,
      idSubCategory,
      query,
      internal,
      external,
      height,
      year,
      brand,
      model
      //page
    );

    if (idUser === 'usr-100008') {
      clearBrands();
      clearModels();
    }

    clearErrors();
    clearSearch();
    if (idUser === 'usr-100008') {
      navigate({
        pathname: supplier !== null ? `${supplier[0].uri}` : '/products',
        search:
          '?' +
          createSearchParams({
            category: subCategory,
            query,
            car1: internal,
            car2: external,
            car3: height,
            year,
            brand,
            model
            //page
          })
      });
    } else {
      navigate({
        pathname: supplier !== null ? `${supplier[0].uri}` : '/products',
        search:
          '?' +
          createSearchParams({
            category: subCategory,
            query
            //page
          })
      });
    }
  };

  return (
    <StyleForm onSubmit={handleSubmit}>
      <input
        ref={searchValue}
        onChange={handleChange}
        type="text"
        placeholder="Encontralo lo que buscas"
      />
      {idUser === 'usr-100008' && (
        <SearchTiti>
          <div className="input-titi">
            <input
              ref={internalValue}
              onChange={handleChange}
              type="number"
              step="0.01"
              min="0"
              placeholder="Interno"
            />
            <input
              ref={externalValue}
              onChange={handleChange}
              type="number"
              step="0.01"
              min="0"
              placeholder="Externo"
            />
            <input
              ref={heightValue}
              onChange={handleChange}
              type="number"
              step="0.01"
              min="0"
              placeholder="Altura"
            />
          </div>

          <div className="select-titi">
            <select ref={yearValue} onChange={handleYearChange}>
              <option value="Todos" defaultChecked>
                Año
              </option>
              {years !== null &&
                years.map((year) => (
                  <option key={year.value} value={year.value}>
                    {year.value}
                  </option>
                ))}
            </select>

            <select ref={brandValue} onChange={handleBrandChange} name="brand">
              <option value="Todos" defaultChecked>
                Marca
              </option>
              {brands !== null &&
                brands.map((brand) => (
                  <option key={brand.brand} value={brand.brand}>
                    {brand.brand}
                  </option>
                ))}
            </select>

            <select ref={modelValue} name="Model">
              <option value="Todos" defaultChecked>
                Modelo
              </option>
              {models !== null &&
                models.map((model) => (
                  <option key={model.model} value={model.model}>
                    {model.model}
                  </option>
                ))}
            </select>
          </div>
        </SearchTiti>
      )}
      <button type="submit" className="search-icon">
        <img src={searchIcon} alt="search icon" />
      </button>
    </StyleForm>
  );
};

export default Search;
