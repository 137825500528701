import React, { useContext } from 'react';

import ProductContext from './context/productsContext/ProductContext';

import Global from './styles/GlobalStyles';

import RoutesComponent from './routes/Routes';

function App() {
  const { supplier } = useContext(ProductContext);
  let primaryColor = '';
  let secondaryColor = '';
  let infoColor = '';
  let textColor = '';

  if (supplier !== null) {
    const supplierColor = supplier[0];
    primaryColor = supplierColor.primaryColor;
    secondaryColor = supplierColor.secondaryColor;
    infoColor = supplierColor.infoColor;
    textColor = supplierColor.textColor;
  }

  return (
    <>
      <Global
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        infoColor={infoColor}
        textColor={textColor}
      />
      <RoutesComponent />
    </>
  );
}

export default App;
