import styled from 'styled-components';

const Supplier = styled.div`
  background-color: var(--whiteColor);
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-around;
  margin-bottom: 1rem;

  .supplier-img {
    background-color: var(--primaryColorSombra);
    width: 25%;
    text-align: center;
    padding-top: 0.5rem;
  }
  a {
    color: var(--textColor);
    margin: 0.5rem 1rem;
    width: 100%;
    text-decoration: none;
    font-size: 1.7rem;
  }
`;

export default Supplier;
