import styled from 'styled-components';

const FooterContainer = styled.footer`
  border: 1px solid var(--darkBlueColor);
  background-color: var(--darkBlueColor);
  color: var(--whiteColor);
  padding: 0 1rem;

  .footer-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 2rem 0;

    h3 {
      margin: 0;
      margin-top: 0.5rem;
    }

    a {
      text-decoration: none;
      color: var(--whiteColor);
    }

    img {
      width: 1.2rem;
      margin-top: 0.5rem;
      margin-right: 0.5rem;
    }

    .footer-redes {
      text-align: right;
      img {
        width: 1.5rem;
        margin: 0;
        margin-top: 0.7rem;
        margin-left: 0.5rem;
      }
    }
  }

  .footer-legales {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.5rem 0;
  }

  @media (min-width: 768px) {
    padding: 0 3rem;

    .footer-info {
      vertical-align: center;
      h3 {
        font-size: 1.5rem;
      }
      img {
        width: 1.7rem;
      }
      span {
        font-size: 1.5rem;
      }

      .footer-redes {
        img {
          width: 2.2rem;
          margin-top: 1rem;
          margin-left: 0.7rem;
        }
      }
    }

    .footer-legales {
      justify-content: center;

      h5 {
        margin: 0 1.5rem;
        font-size: 1.2rem;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 0 5rem;
  }

  @media (min-width: 1200px) {
    padding: 0;

    .footer-info {
      margin: 3rem auto;
      width: 1250px;
    }
    .footer-legales {
      margin: 3rem 0;
    }
  }
`;

export default FooterContainer;
