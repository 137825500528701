import styled from 'styled-components';

export const StyleForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--whiteColor);
  border-radius: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;

  input {
    border: none;
    color: var(--textColor);
    margin-left: 0.8rem;
    font-size: 1.3rem;
    width: 85%;
    height: 2.5rem;
    z-index: 1;

    &::placeholder {
      color: var(--textColorSombra);
    }

    &:focus {
      outline: none;
    }
  }
  button {
    z-index: 1;
  }

  .search-icon {
    background-color: var(--secondaryColor);
    border-radius: 0 0.5rem 0.5rem 0;
    border: none;
    cursor: pointer;
    width: 15%;
    text-align: center;

    img {
      margin-top: 0.2rem;
    }
  }

  @media (min-width: 768px) {
    input {
      width: 90%;
      height: 3rem;
    }
    .search-icon {
      width: 10%;
      img {
        margin-top: 0.5rem;
      }
    }
  }

  @media (min-width: 1024px) {
    position: absolute;
    width: 60%;
    top: 1.2rem;
    left: 23%;
    input {
      width: 88%;
      height: 3.5rem;
      font-size: 1.7rem;
    }
    .search-icon {
      width: 12%;
      img {
        margin-top: 0.4rem;
        width: 2.7rem;
      }
    }
  }

  @media (min-width: 1200px) {
    width: 55%;
    top: 1.2rem;
    left: 25%;
  }
`;

export const SearchTiti = styled.div`
  position: absolute;
  width: 94%;

  .input-titi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 3.2rem;

    input {
      border-radius: 0.5rem;
      margin: 0;
      padding-left: 0.7rem;
      width: 32%;
    }
  }

  .select-titi {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;

    select {
      background-color: var(--whiteColor);
      border: none;
      border-radius: 0.5rem;
      color: var(--textColor);
      cursor: pointer;
      font-size: 1.3rem;
      padding: 0.3rem 0.7rem;
      margin: 0;
      width: 32%;
      outline: none;
    }
  }

  @media (min-width: 425px) {
    width: 95%;
    .select-titi {
      margin-top: 0.7rem;
    }
  }

  @media (min-width: 768px) {
    width: 92%;
    .input-titi {
      margin-top: 3.7rem;
      input {
        padding-left: 1rem;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 100%;
    .input-titi {
      margin-top: 4.3rem;
    }
    .select-titi {
      margin-top: 0.8rem;
      select {
        font-size: 1.5rem;
        padding: 0.5rem 0.7rem;
      }
    }
  }
`;
