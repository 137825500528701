import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import ProductContext from '../../context/productsContext/ProductContext';

import styled from 'styled-components';

const Supplier = ({ img, link, user }) => {
  const { clearErrors, getProductsBySupplier, getSupplier } =
    useContext(ProductContext);

  return (
    <CardSupplier>
      <div className="card-supplier-img">
        <img src={img} alt="proveedores" />
      </div>
      <Link
        className="linkButton"
        to={link}
        onClick={() => {
          clearErrors();
          getSupplier(user);
          getProductsBySupplier(user);
        }}
      >
        Ver productos
      </Link>
    </CardSupplier>
  );
};

const CardSupplier = styled.div`
  background-color: var(--whiteColor);
  border-radius: 1rem;
  padding: 1rem;
  height: 16rem;

  .linkButton {
    background-color: var(--primaryColor);
    border: none;
    border-radius: 0.3rem;
    color: var(--whiteColor);
    cursor: pointer;
    padding: 0.5rem 1rem;
    outline: none;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    float: right;
    text-decoration: none;
  }

  .card-supplier-img {
    border-radius: 1rem;
    text-align: center;
    img {
      min-height: 11.5rem;
      max-height: 11.5rem;
    }
  }

  @media (min-width: 768px) {
    width: 97%;
  }
`;

export default Supplier;
