import React from 'react';

import { StyleForm } from '../../../styles/components/StyleForm';

const LoginForm = () => {
  return (
    <StyleForm>
      <input type="Number" name="phone" placeholder="Número de Celular" />
      <input
        type="password"
        name="password"
        placeholder="Ingrese su contraseña"
      />
      <div className="form-check">
        <input type="checkbox" id="remember" name="isRemindMe" />
        <label htmlFor="remember">Recordarme en este equipo.</label>
      </div>
      <a href="#/" className="form-remenber">
        ¿Olvidaste tu contraseña?
      </a>
      <button type="submit" className="form-login-btn">
        Iniciar Sesión
      </button>
    </StyleForm>
  );
};

export default LoginForm;
