import React from 'react';

import { Link } from 'react-router-dom';

import Supplier from '../../styles/components/StyleSupplierHambuerger';

import boxImg from '../../assets/icons/boxesBlue.svg';

import { SUPPLIERS } from '../utils';

const SupplierHamburger = ({
  handleClick,
  getSupplier,
  getYears,
  getProductsBySupplier,
  getCategoryBySupplier,
  clearErrors
}) => {
  return SUPPLIERS.map((supplier) => (
    <Supplier key={supplier.idUser}>
      <div className="supplier-img">
        <img src={boxImg} alt="boxes" />
      </div>
      <Link
        to={`/${supplier.uri}`}
        onClick={() => {
          handleClick();
          clearErrors();
          getSupplier(supplier.idUser);
          getProductsBySupplier(supplier.idUser);
          getCategoryBySupplier(supplier.idUser);
          supplier.idUser === 'usr-100008' && getYears();
        }}
      >
        {supplier.userName}
      </Link>
    </Supplier>
  ));
};

export default SupplierHamburger;
