import React from 'react';
import styled from 'styled-components';

import imgCart from '../../assets/icons/cartButtonWhite.svg';
import imgProduct from '../../assets/images/moto.png';

const CardRecommended = () => {
  return (
    <StyleCardRecommended>
      <div className="recommended-img">
        <img src={imgProduct} alt="" />
      </div>

      <div>
        <h4 className="name-product">
          Nombre del producto agregando otros datos
        </h4>
      </div>

      <div className="price-btn">
        <p className="card-recommended-price">
          <span>US$</span>350
        </p>

        <button className="card-recommended-btn">
          <img src={imgCart} alt="" />
          Agregar
        </button>
      </div>
    </StyleCardRecommended>
  );
};

const StyleCardRecommended = styled.div`
  background-color: var(--whiteColor);
  border-radius: 0.5rem;
  box-shadow: 3px 3px 3px #e5e5e5;
  margin: 0.5rem;
  padding: 0.5rem;
  min-height: 20rem;

  .recommended-img {
    min-height: 9.8rem;
  }

  .name-product {
    margin: 0;
    font-family: var(--fontTitle);
    font-size: 1.2rem;
    margin-bottom: 0.7rem;
    text-align: start;
  }

  .price-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 0.5rem;

    .card-recommended-price {
      margin: 0;
      font-size: 1.6rem;

      span {
        position: relative;
        top: -0.4rem;
        font-size: 1rem;
      }
    }

    .card-recommended-btn {
      background-color: var(--primaryColor);
      border: none;
      border-radius: 0.5rem;
      color: var(--whiteColor);
      cursor: pointer;
      padding: 0.2rem 0.7rem 0.4rem;
      font-size: 1.2rem;
      outline: none;
    }
  }

  @media (min-width: 768px) {
    padding: 1rem;
    min-height: 25rem;

    .recommended-img {
      min-height: 16rem;
    }

    .name-product {
      font-size: 1.3rem;
    }

    .price-btn {
      margin-bottom: 0.3rem;
      align-items: flex-end;

      .card-recommended-price {
        font-size: 1.8rem;

        span {
          top: -0.4rem;
          font-size: 1.2rem;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    min-height: 21rem;
    .recommended-img {
      min-height: 11.5rem;
    }
  }
`;

export default CardRecommended;
