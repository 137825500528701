export const URL = {
  GET_PRODUCTS:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=buscar&p=all&pagina=',
  SEARCH_PRODUCTS: 'https://sabaneo.com/php/dao/SelkisCON.php?function=buscar',
  GET_PRODUCT:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=getProducto&idproducto=',
  GET_IMAGE: 'https://selkisbolivia.com/bd/configuracion/',
  GET_PRODUCTS_BY_SUPPLIER:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=buscar&p=',
  GET_PRODUCTS_BY_TITI:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=buscar&cat=Todos&ano=Todos&marca=Todos&modelo=Todos&p=',
  GET_CATEGORY_BY_SUPPLIER:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=buscarAllCategorias&idproveedor=',
  SEARCH_BY_CATEGORY:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=buscar&q=&p=',
  SEARCH_BY_CATEGORY_TITI:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=buscar&q=&car-1=&car-2=&car-3=&ano=Todos&marca=Todos&modelo=Todos&pagina=1&p=',
  GET_YEARS: 'https://sabaneo.com/php/dao/ModeloCON.php?function=getAno',
  GET_BRANDS:
    'https://sabaneo.com/php/dao/ModeloCON.php?function=getMarca&ano=',
  GET_MODELS:
    'https://sabaneo.com/php/dao/ModeloCON.php?function=getModelo&ano=',
  GET_BRANCH_OFFICES:
    'https://sabaneo.com/php/dao/SelkisCON.php?function=getSucursales&idusuario='
};
