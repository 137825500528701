import styled from 'styled-components';

import heroImgMobile from '../../assets/images/heroMobile.png';
import heroImgTablet from '../../assets/images/heroTablet.png';
import heroImgLaptop from '../../assets/images/heroLaptop.png';

const StyleHero = styled.div`
  position: relative;
  background-color: var(--whiteColor);
  border-radius: 1rem;
  margin: 0 auto;
  margin-top: 1rem;
  width: 94%;

  .hero-img {
    background-image: url(${heroImgMobile});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 100%;
    height: 32rem;
  }
  .text-title {
    color: var(--textColor);
    font-family: var(--fontTitle);
    font-size: 1.1rem;
    text-align: right;
    position: absolute;
    top: 38%;
    right: 1rem;
    width: 45%;
  }

  @media (min-width: 425px) {
    .hero-img {
      height: 38rem;
    }

    .text-title {
      font-size: 1.3rem;
    }
  }

  @media (min-width: 768px) {
    margin-top: 1.5rem;
    padding: 0.5rem;
    width: 92.5%;
    .hero-img {
      background-image: url(${heroImgTablet});
    }
    .text-title {
      font-size: 1.7rem;
      width: 100%;
      top: 44%;
      right: 1.5rem;
    }
  }

  @media (min-width: 1024px) {
    padding: 0.5rem;
    width: 91%;
    margin-top: 2rem;
    .hero-img {
      background-image: url(${heroImgLaptop});
      height: 45rem;
    }
    .text-title {
      font-size: 2.2rem;
      top: 44%;
      right: 1.8rem;
    }
  }

  @media (min-width: 1200px) {
    width: 1250px;
  }
`;

export default StyleHero;
