import React from 'react';

import styled from 'styled-components';

import CardProfileUser from '../pure/CardProfileUser';
import CartProdcut from '../pure/CartProduct';

const ProfileUser = () => {
  return (
    <StyleProfileUser>
      <CardProfileUser />
      <div className="profile-section">
        <h2 className="section-tittle">Mis compras</h2>
        <CartProdcut />
        <CartProdcut />
        <CartProdcut />
      </div>
    </StyleProfileUser>
  );
};

const StyleProfileUser = styled.div`
  color: var(--textColor);
  padding: 1rem;

  .profile-section {
    .section-tittle {
      font-family: var(--fontTitle);
      font-size: 1.8rem;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-height: 70vh;
    padding: 1rem 3rem;

    .profile-section {
      width: 55%;
      .section-tittle {
        font-size: 2.2rem;
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 1.5rem 5rem;
  }

  @media (min-width: 1200px) {
    max-width: 1250px;
    padding: 0;
    margin: 2rem auto;

    .profile-section {
      width: 50%;
    }
  }
`;

export default ProfileUser;
