import styled from 'styled-components';

export const SupplierHamburger = styled.div`
  border: 1px solid var(--darkBlueColor);
  text-align: center;
  position: relative;
  background-color: var(--whiteColor);
  border-radius: 0.5rem;
  min-height: 9rem;

  img {
    border-radius: 1rem;
    margin-top: 5px;
    max-width: 95%;
    max-height: 10rem;
  }

  h2 {
    color: var(--primaryColor);
    font-family: var(--fontTitle);
    font-size: 2.7rem;
    margin-bottom: 0;
  }

  p {
    color: var(--textColorSombra);
    font-size: 1.2rem;
    margin-top: 0;
  }

  .close-btn {
    position: absolute;
    background-color: ${(props) =>
      props.supplierId === 'usr-100008'
        ? 'var(--secondaryColor)'
        : 'var(--primaryColor)'};
    border-radius: 0 0.5rem 0 0.5rem;
    color: var(--whiteColor);
    cursor: pointer;
    width: 11%;
    top: 0;
    right: 0;
    text-align: center;
    padding: 0.1rem;

    span {
      font-family: var(--fontTitle);
      font-size: 1.3rem;
    }
  }

  @media (min-width: 768px) {
    .close-btn {
      width: 15%;

      span {
        font-size: 1.5rem;
      }
    }
  }

  @media (min-width: 1024px) {
    min-height: 12rem;
    h2 {
      font-size: 3.5rem;
    }

    p {
      font-size: 1.8rem;
    }

    .close-btn {
      width: 13%;

      span {
        font-size: 2rem;
      }
    }
  }
`;

export const TitleCategory = styled.h2`
  color: var(--whiteColor);
  font-family: var(--fontTitle);
  font-size: 2rem;
  letter-spacing: 0.1rem;
`;

export const LinksHamburger = styled.div`
  border-bottom: 1px solid var(--primaryColorSombra);
  color: var(--whiteColor);
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  padding: 0.5rem;

  img {
    width: 1.8rem;
  }

  span {
    margin-left: 1rem;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  a {
    color: var(--whiteColor);
    text-decoration: none;
    font-family: var(--fontText);

    img {
      width: 1.8rem;
    }

    span {
      margin-left: 1rem;
      font-size: 1.5rem;
    }
  }
`;

export const LinksHamburgerClient = styled.div`
  border-bottom: 1px solid var(--primaryColorSombra);
  color: var(--whiteColor);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-top: 0.5rem;
  padding: 0.5rem;

  img {
    width: 1.8rem;
  }

  span {
    margin-left: 1rem;
    font-size: 1.5rem;
    margin-bottom: 0.2rem;
  }

  a {
    color: var(--whiteColor);
    text-decoration: none;
    font-family: var(--fontText);

    img {
      width: 1.8rem;
    }

    span {
      margin-left: 1rem;
      font-size: 1.5rem;
    }
  }
`;
