import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import ProductContext from '../../context/productsContext/ProductContext';

import DetailContainer from '../../styles/components/StyleDetails';
import DetailProduct from '../pure/DetailProduct';

import { SUPPLIERS } from '../utils';
import BranchOffices from './BranchOffices';

const Details = () => {
  const {
    detailProduct,
    getDetail,
    getImage,
    getWhatsapp,
    getBranchOffices,
    supplier,
    getSupplier,
    getProductsBySupplier,
    branchOffices
  } = useContext(ProductContext);

  let supplierId = null;
  if (supplier !== null) supplierId = supplier[0].idUser;

  const [loading, setLoading] = useState(false);
  const [openBranchOffice, setOpenBranchOffice] = useState(false);

  const params = useParams();

  const location = useLocation();
  const { pathname } = location;
  let urlSplit = pathname.split('/');
  urlSplit = urlSplit[1];

  const newSupplier = [...SUPPLIERS].filter((el) => el.uri === urlSplit);

  const { id } = params;

  useEffect(() => {
    if (detailProduct.length === 0) getDetail(id);
    if (detailProduct.product !== undefined) setLoading(true);
  }, [detailProduct, getDetail, id, loading]);

  useEffect(() => {
    if (newSupplier.length > 0) {
      if (urlSplit === newSupplier[0].uri) {
        getSupplier(newSupplier[0].idUser);
        getProductsBySupplier(newSupplier[0].idUser);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenBranchOffice = () => {
    setOpenBranchOffice(!openBranchOffice);
  };

  return (
    <DetailContainer>
      {loading && (
        <DetailProduct
          product={detailProduct}
          getImage={getImage}
          getWhatsapp={getWhatsapp}
          getBranchOffices={getBranchOffices}
          supplierId={supplierId}
          handleOpenBranchOffice={handleOpenBranchOffice}
          openBranchOffice={openBranchOffice}
        />
      )}
      {openBranchOffice && (
        <BranchOffices
          branchOffices={branchOffices}
          handleOpenBranchOffice={handleOpenBranchOffice}
        />
      )}
    </DetailContainer>
  );
};

export default Details;
