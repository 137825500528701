import React from 'react';

import SupplierHamburger from '../pure/SupplierHamburger';
import NavHamburger from '../pure/NavHamburger';

import ContainerHamburger from '../../styles/components/StyleHamburger';

const Hamburger = ({
  open,
  handleClick,
  clearSearch,
  getProducts,
  getSupplier,
  getYears,
  getProductsBySupplier,
  getCategoryBySupplier,
  clearErrors
}) => {
  return (
    <ContainerHamburger open={open}>
      <NavHamburger
        handleClick={handleClick}
        clearSearch={clearSearch}
        getProducts={getProducts}
        clearErrors={clearErrors}
      />
      <h2 className="menu-title">Proveedores</h2>
      <SupplierHamburger
        handleClick={handleClick}
        getSupplier={getSupplier}
        getProductsBySupplier={getProductsBySupplier}
        getCategoryBySupplier={getCategoryBySupplier}
        clearErrors={clearErrors}
        getYears={getYears}
      />
    </ContainerHamburger>
  );
};

export default Hamburger;
