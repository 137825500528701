import styled from 'styled-components';

const StyleHeader = styled.header`
  background-color: var(--primaryColor);
  height: ${(props) => (props.isTiti ? '14.2rem' : 'auto')};
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 1rem 3rem;
    height: ${(props) => (props.isTiti ? '15.4rem' : 'auto')};
  }

  @media (min-width: 1024px) {
    padding: 2rem 5rem;
    height: ${(props) => (props.isTiti ? '14.5rem' : 'auto')};
  }
`;

export default StyleHeader;
