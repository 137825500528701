import React from 'react';
import styled from 'styled-components';

import CardBranchOffice from '../pure/CardBranchOffice';

const BranchOffices = ({ branchOffices, handleOpenBranchOffice }) => {
  return (
    <BranchOfficesContainer>
      {branchOffices !== null &&
        branchOffices.map((item) => (
          <CardBranchOffice
            key={item.idsucursal}
            branchOffice={item}
            handleOpenBranchOffice={handleOpenBranchOffice}
          />
        ))}
    </BranchOfficesContainer>
  );
};

const BranchOfficesContainer = styled.div`
  background-color: var(--textColorSombra);
  border-radius: 1rem;
  color: var(--textColor);
  padding: 1rem;
  margin-top: 1rem;
  position: relative;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
  }
`;

export default BranchOffices;
