import React from 'react';

import { Link } from 'react-router-dom';

import {
  SupplierHamburger,
  LinksHamburger
} from '../../styles/components/StyleNavHamburger';

import homeIcon from '../../assets/icons/homeWhite.svg';
import userIcon from '../../assets/icons/userWhite.svg';
import searchIcon from '../../assets/icons/SearchWhite.svg';
import cartIcon from '../../assets/icons/cartWhite.svg';
import onIcon from '../../assets/icons/on-offWhite.svg';

const NavHamburger = ({ handleClick, clearSearch, getProducts }) => {
  return (
    <>
      <SupplierHamburger>
        <h2>Sabaneo.com</h2>
        <p>Lo que buscas, lo encuentras</p>
        <div className="close-btn" onClick={handleClick}>
          <span>X</span>
        </div>
      </SupplierHamburger>

      <LinksHamburger>
        <Link
          to="/"
          onClick={() => {
            handleClick();
            clearSearch();
            getProducts();
          }}
        >
          <img src={homeIcon} alt="home" />
          <span>Inicio</span>
        </Link>
      </LinksHamburger>

      <LinksHamburger>
        <Link to="/profile" onClick={handleClick}>
          <img src={userIcon} alt="user" />
          <span>Mi perfil</span>
        </Link>
      </LinksHamburger>

      <LinksHamburger>
        <Link to="/products" onClick={handleClick}>
          <img src={searchIcon} alt="search" />
          <span>productos</span>
        </Link>
      </LinksHamburger>

      <LinksHamburger>
        <Link to="/cart" onClick={handleClick}>
          <img src={cartIcon} alt="cart" />
          <span>Mi carrito</span>
        </Link>
      </LinksHamburger>

      <LinksHamburger>
        <Link to="/login" onClick={handleClick}>
          <img src={onIcon} alt="iniciar session" />
          <span>Iniciar sesión</span>
        </Link>
      </LinksHamburger>
    </>
  );
};

export default NavHamburger;
