import React from 'react';

import ContainerHamburger from '../../styles/components/StyleHamburger';
import NavHamburgerClient from '../pure/NavHamburgerClient';

const HamburgerClient = ({
  open,
  handleClick,
  clearSearch,
  getProducts,
  getProductsBySupplier,
  clearErrors,
  categories,
  supplier,
  searchProductsByCategory,
  handleSubCategory,
  handleNameCategory
}) => {
  return (
    <ContainerHamburger open={open}>
      <NavHamburgerClient
        handleClick={handleClick}
        clearSearch={clearSearch}
        getProducts={getProducts}
        clearErrors={clearErrors}
        categories={categories}
        supplier={supplier}
        handleSubCategory={handleSubCategory}
        handleNameCategory={handleNameCategory}
        searchProductsByCategory={searchProductsByCategory}
        getProductsBySupplier={getProductsBySupplier}
      />
    </ContainerHamburger>
  );
};

export default HamburgerClient;
