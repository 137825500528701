import React from 'react';

import Suppliers from '../components/container/Suppliers';
import Featured from '../components/container/Featured';
import HeroComponent from '../components/container/Hero';

const Home = () => {
  return (
    <>
      <HeroComponent />
      <Suppliers />
      <Featured />
    </>
  );
};

export default Home;
