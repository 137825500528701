import styled from 'styled-components';

export const H2 = styled.h2`
  color: var(--textColor);
  font-family: var(--fontTitle);
  font-size: 2.5rem;
  margin: 0;
  margin-left: 1rem;
  margin-bottom: 1.5rem;

  @media (min-width: 768px) {
    font-size: 2.8rem;
    margin-left: 3rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
    margin-left: 5rem;
  }

  @media (min-width: 1200px) {
    width: 1250px;
    margin: 0.5rem auto 2.5rem;
    font-size: 4rem;
  }
`;

export const FeaturedContainer = styled.div`
  padding: 0 1rem;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 3rem;
  }

  @media (min-width: 1024px) {
    padding: 0 5rem;
  }

  @media (min-width: 1200px) {
    width: 1250px;
    margin: 0 auto;
    padding: 0;
  }
`;
