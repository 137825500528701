import React from 'react';

import StyleHero from '../../styles/components/StyleHero';

const HeroComponent = () => {
  return (
    <StyleHero>
      <div className="hero-img"></div>
      <div className="text-title">
        <h2>Lo que buscas, lo encuentras</h2>
      </div>
    </StyleHero>
  );
};

export default HeroComponent;
