import { createGlobalStyle } from 'styled-components';

// ${(props) => (props.isClient ? '#8EC641' : '#FD7E14')}

const Global = createGlobalStyle`

  :root{
    --primaryColor:${(props) =>
      props.primaryColor === '' ? '#FD7E14' : props.primaryColor};
    --primaryColorSombra:${(props) =>
      props.primaryColor === '' ? '#FD7E1450' : `${props.primaryColor}50`};
    --secondaryColor:${(props) =>
      props.secondaryColor === '' ? '#21FCA2' : props.secondaryColor};
    --secondaryColorSombra:${(props) =>
      props.secondaryColor === '' ? '#21FCA250' : `${props.secondaryColor}50`};
    --infoColor:${(props) =>
      props.infoColor === '' ? '#0858FC' : props.infoColor};
    --whiteColor: #ffffff;
    --darkBlueColor:#232F3E;
    --textColor:${(props) =>
      props.textColor === '' ? '#204FB0' : props.textColor};
    --textColorSombra:#204FB050;
    --fontText:NotoSansRegular;
    --fontMedium:NotoSansMedium;
    --fontBold:NotoSansBold;
    --fontTitle:NotoSansBlack;
  }
`;

export default Global;
