import React, { useContext } from 'react';
import ProductContext from '../../context/productsContext/ProductContext';

import CardProduct from '../pure/CardProduct';

import { H2, FeaturedContainer } from '../../styles/components/StyleFeatured';

const Featured = ({ pageEnd, loading, nextPage }) => {
  const { products, getDetail, search, errors, getImage, supplier } =
    useContext(ProductContext);
  let supplierId = null;
  if (supplier !== null) supplierId = supplier[0].idUser;

  return (
    <>
      <H2>
        {errors !== null ? (
          <span>{errors}</span>
        ) : (
          <>{search !== null ? 'Productos encontrados' : 'Destacados'}</>
        )}
      </H2>
      <FeaturedContainer>
        {search !== null
          ? search.map((product) => (
              <CardProduct
                key={product.idproducto}
                product={product}
                getDetail={getDetail}
                getImage={getImage}
                supplierId={supplierId}
                supplier={supplier}
              />
            ))
          : products.map((product) => (
              <CardProduct
                key={product.idproducto}
                product={product}
                getDetail={getDetail}
                getImage={getImage}
                supplierId={supplierId}
                supplier={supplier}
              />
            ))}
      </FeaturedContainer>
    </>
  );
};

export default Featured;
