import React from 'react';

import imgProfile from '../../assets/images/avatar.jpeg';
import editIcon from '../../assets/icons/editWhite.svg';
import styled from 'styled-components';

const CardProfileUser = () => {
  return (
    <StyleCardProfileUser>
      <div className="profile-user-img">
        <img src={imgProfile} alt="" />
      </div>

      <div className="profile-user-detail">
        <div>
          <h4>Nombre:</h4>
          <h3>John Doe</h3>
        </div>

        <div>
          <h4>Celular:</h4>
          <h3>78787878</h3>
        </div>

        <div>
          <h4>Correo electronico:</h4>
          <h3>contactopersonal@gmail.com</h3>
        </div>
      </div>

      <div className="profile-close">
        <img src={editIcon} alt="edit profile" />
      </div>
    </StyleCardProfileUser>
  );
};

const StyleCardProfileUser = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: var(--whiteColor);
  border-radius: 1rem;
  box-shadow: 3px 3px 3px var(--textColorSombra);

  .profile-user-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32%;
    padding: 0.5rem;
    img {
      border-radius: 50%;
    }
  }

  .profile-user-detail {
    width: 68%;
    padding: 0.5rem;

    div {
      margin-bottom: 0.7rem;
    }

    div > h3,
    h4 {
      margin: 0;
    }
    div > h4 {
      color: var(--textColorSombra);
    }
  }

  .profile-close {
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--textColor);
    border-radius: 0 1rem 0 1rem;
    cursor: pointer;
    padding: 0.4rem 0.7rem;
  }

  @media (min-width: 425px) {
    .profile-user-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30%;
      padding: 0.5rem;
      img {
        border-radius: 50%;
      }
    }

    .profile-user-detail {
      width: 68%;
      padding: 0.7rem;

      div > h3 {
        font-size: 1.5rem;
      }

      div > h4 {
        font-size: 1.2rem;
      }
    }
  }

  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: center;
    height: 25rem;
    margin-top: 2rem;
    width: 40%;
    padding: 1rem;

    .profile-user-img {
      margin: 0 auto;
      width: 40%;
    }
  }

  @media (min-width: 1024px) {
    height: 33rem;

    .profile-user-detail {
      padding: 1rem;

      div > h3 {
        font-size: 1.8rem;
      }

      div > h4 {
        font-size: 1.4rem;
      }
    }

    .profile-close {
      padding: 0.5rem 0.9rem;
    }
  }

  @media (min-width: 1200px) {
    height: 37rem;
  }
`;

export default CardProfileUser;
