import React from 'react';
import { Link } from 'react-router-dom';

import LoginForm from '../pure/forms/LoginForm';

import {
  StyleImageForm,
  StyleContainerForm
} from '../../styles/components/StyleForm';

const LoginUser = () => {
  return (
    <StyleImageForm>
      <StyleContainerForm>
        <div className="container-form">
          <h2 className="form-title">Sabaneo</h2>

          <LoginForm />

          <p className="form-link">
            ¿Eres nuevo? <Link to="/register">Registrate</Link>
          </p>
          <p className="form-info">
            Para poder cotizar y/o comprar productos en nuestra plataforma,
            tiene que iniciar sesión.
          </p>
        </div>
      </StyleContainerForm>
    </StyleImageForm>
  );
};

export default LoginUser;
