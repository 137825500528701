import React from 'react';
import { Link } from 'react-router-dom';

import infoImg from '../../assets/icons/infoButtonWhite.svg';
import buyImg from '../../assets/icons/cartButtonWhite.svg';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';

import {
  Card,
  CardImage,
  H3,
  CardStarts,
  CardInfo,
  CardButtons
} from '../../styles/components/StyleCardProduct';

const CardProduct = ({
  product,
  getDetail,
  getImage,
  supplierId,
  supplier
}) => {
  let price = parseFloat(product.precio);

  const { idproducto, nombre, precio, nombree, link } = product;

  return (
    <Card>
      <CardImage onClick={() => getDetail(idproducto)}>
        <Link
          to={
            supplier !== null
              ? `/${supplier[0].uri}/product-detail/${idproducto}`
              : `/product-detail/${idproducto}`
          }
        >
          <img src={getImage(link)} alt={nombre} />
        </Link>
      </CardImage>

      <H3 onClick={() => getDetail(idproducto)}>
        <Link
          to={
            supplier !== null
              ? `/${supplier[0].uri}/product-detail/${idproducto}`
              : `/product-detail/${idproducto}`
          }
        >
          {nombre}
        </Link>
      </H3>

      <CardStarts supplierId={supplierId}>
        <FaStar className="card-start" />
        <FaStar className="card-start" />
        <FaStar className="card-start" />
        <FaStarHalfAlt className="card-start" />
        <FaRegStar className="card-start" />
      </CardStarts>

      <CardInfo>
        <div className="card-info-price">
          {price === -1 || price === 0 ? (
            <p className="card-info-login">Iniciar sesión</p>
          ) : (
            <>
              <p className="info-price">
                US$<span>350</span>
              </p>
              <p className="info-price">
                Bs<span>{precio}</span>
              </p>
            </>
          )}
        </div>

        <div className="info-distribuidor">
          <p>Distribuidor</p>
          <h4>{nombree}</h4>
        </div>
      </CardInfo>

      <CardButtons supplierId={supplierId}>
        <Link
          to={
            supplier !== null
              ? `/${supplier[0].uri}/product-detail/${idproducto}`
              : `/product-detail/${idproducto}`
          }
          onClick={() => getDetail(idproducto)}
          className="button-info"
        >
          <div>
            <img src={infoImg} alt="" />
          </div>
          <span>Más información</span>
        </Link>

        <div className="button-buy">
          <div>
            <img src={buyImg} alt="" />
          </div>
          <span>Comprar</span>
        </div>
      </CardButtons>
    </Card>
  );
};

export default CardProduct;
