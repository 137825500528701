import React from 'react';

import emailImg from '../../assets/icons/emailWhite.svg';
import phoneImg from '../../assets/icons/phoneWhite.svg';
import whatsAppImg from '../../assets/icons/whatsAppWhite.svg';
import faceImg from '../../assets/icons/facebookWhite.svg';
import twitterImg from '../../assets/icons/twitterWhite.svg';
import instagranImg from '../../assets/icons/instagranWhite.svg';
import linkedinImg from '../../assets/icons/linkedinWhite.svg';

import FooterContainer from '../../styles/components/StyleFooter';

const Footer = () => {
  return (
    <FooterContainer>
      <div>
        <div className="footer-info">
          <div>
            <h3>Vende productos en Sabaneo</h3>
            <h3>Contactanos:</h3>
            <div>
              <a href="/#">
                <img src={emailImg} alt="email" />
                <span>contacto@sabaneo.com</span>
              </a>
            </div>
            <div>
              <a href="/#">
                <img src={phoneImg} alt="telefono" />
                <span>3360000</span>
              </a>
            </div>
            <div>
              <a href="/#">
                <img src={whatsAppImg} alt="whatsapp" />
                <span>78757671</span>
              </a>
            </div>
          </div>

          <div className="footer-redes">
            <h3>Siguenos:</h3>
            <div>
              <a href="/#">
                <img src={faceImg} alt="facebook" />
              </a>
              <a href="/#">
                <img src={twitterImg} alt="twitter" />
              </a>
              <a href="/#">
                <img src={instagranImg} alt="instagran" />
              </a>
              <a href="/#">
                <img src={linkedinImg} alt="linkedin" />
              </a>
            </div>
          </div>
        </div>

        <div className="footer-legales">
          <h5>Condiciones de uso</h5>
          <h5>Aviso de privacidad</h5>
          <h5>&copy; 2000-2022 sabaneo.com</h5>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
