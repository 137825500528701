import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import ProductContext from '../../context/productsContext/ProductContext';

import Nav from '../pure/Nav';
import Search from '../pure/forms/Search';
import Hamburger from './Hamburger';

import Header from '../../styles/components/StyleHeader';

import { SUPPLIERS } from '../utils';
import HamburgerClient from './HamburgerClient';

const HeaderComponent = () => {
  const {
    clearSearch,
    getProducts,
    searchProducts,
    getProductsBySupplier,
    clearErrors,
    isSupplier,
    getSupplier,
    categories,
    supplier,
    clearCategoryAndSupplier,
    searchProductsByCategory,
    getYears,
    years,
    getBrands,
    brands,
    getModels,
    models,
    clearBrands,
    clearModels,
    getCategoryBySupplier
  } = useContext(ProductContext);

  const [open, setOpen] = useState(false);
  const [subCategory, setSubCategory] = useState('Todos');
  const [nameCategory, setNameCategory] = useState('all');

  const [searchParams] = useSearchParams();

  const params = Object.fromEntries([...searchParams]);
  // comprobamos que paramas por lo menos tenga un objeto
  const isPramsEmpty = (obj) => {
    return Object.keys(obj).length === 0;
  };

  const location = useLocation();

  let { pathname } = location;

  // separamos la url el nombre del proveedor
  const urlSplit = pathname.split('/');

  const urlName = urlSplit[1];

  const newSupplier = [...SUPPLIERS].filter((el) => el.uri === urlName);

  const isTiti =
    supplier !== null && supplier[0].idUser === 'usr-100008' ? true : false;

  useEffect(() => {
    // cargamos los productos si entra en home o products
    if (pathname === '/' || pathname === '/products') {
      clearCategoryAndSupplier();
      getProducts();
    }

    // cargamos los productos y categorias segun la url del proveedor
    if (newSupplier.length > 0) {
      getSupplier(newSupplier[0].idUser);
      getCategoryBySupplier(newSupplier[0].idUser);
      if (urlName === 'titi') getYears();

      if (pathname === `/${newSupplier[0].uri}`) {
        getProductsBySupplier(newSupplier[0].idUser);
      }

      if (pathname === `/${newSupplier[0].uri}` && isPramsEmpty(params)) {
        getProductsBySupplier(newSupplier[0].idUser);
      }

      if (!isPramsEmpty(params)) {
        let categoryQuery = params.category || subCategory;
        let query = params.query || '';
        let internal = params.car1 || '';
        let external = params.car2 || '';
        let height = params.car3 || '';
        let year = params.year || 'Todos';
        let brand = params.brand || 'Todos';
        let model = params.model || 'Todos';
        let page = params.page || '1';

        console.log(params.car1, params.car2, params.car3);

        searchProducts(
          newSupplier[0].idUser,
          categoryQuery,
          query,
          internal,
          external,
          height,
          year,
          brand,
          model,
          page
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // funcion que oculta o muestra el menu
  function handleClick() {
    setOpen(!open);
  }

  // funcion que conserva el id subCategory
  const handleSubCategory = (idSubCategory) => {
    setSubCategory(idSubCategory);
  };

  // funcion que conserva el nombre de la categoria
  const handleNameCategory = async (nameCategory) => {
    setNameCategory(nameCategory);
  };

  return (
    <Header isTiti={isTiti}>
      <Nav
        open={open}
        handleClick={handleClick}
        clearSearch={clearSearch}
        getProducts={getProducts}
        clearErrors={clearErrors}
        clearCategoryAndSupplier={clearCategoryAndSupplier}
        handleSubCategory={handleSubCategory}
        handleNameCategory={handleNameCategory}
      />

      <Search
        getProducts={getProducts}
        searchProducts={searchProducts}
        clearSearch={clearSearch}
        clearErrors={clearErrors}
        subCategory={subCategory}
        supplier={supplier}
        nameCategory={nameCategory}
        years={years}
        getBrands={getBrands}
        brands={brands}
        getModels={getModels}
        models={models}
        clearBrands={clearBrands}
        clearModels={clearModels}
      />

      {isSupplier ? (
        <HamburgerClient
          open={open}
          handleClick={handleClick}
          clearSearch={clearSearch}
          getProducts={getProducts}
          clearErrors={clearErrors}
          categories={categories}
          supplier={supplier}
          handleSubCategory={handleSubCategory}
          handleNameCategory={handleNameCategory}
          searchProductsByCategory={searchProductsByCategory}
          getProductsBySupplier={getProductsBySupplier}
        />
      ) : (
        <Hamburger
          open={open}
          handleClick={handleClick}
          clearSearch={clearSearch}
          getProducts={getProducts}
          getProductsBySupplier={getProductsBySupplier}
          getCategoryBySupplier={getCategoryBySupplier}
          clearErrors={clearErrors}
          getSupplier={getSupplier}
          getYears={getYears}
        />
      )}
    </Header>
  );
};

export default HeaderComponent;
