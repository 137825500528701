import styled from 'styled-components';

const ContainerHamburger = styled.div`
  background-color: var(--darkBlueColor);
  width: 70%;
  height: 100vh;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: ${(props) => (props.open ? '0' : '-100%')};
  z-index: 1;
  transition: left 0.3s linear;
  overflow-y: scroll;

  .menu-title {
    color: var(--whiteColor);
    font-family: var(--fontTitle);
    font-size: 2rem;
  }

  @media (min-width: 768px) {
    width: 35%;
    padding: 1rem 2rem;
  }

  @media (min-width: 1024px) {
    width: 35%;
    padding: 1rem 2.5rem;
  }

  @media (min-width: 1200px) {
    width: 27%;
  }
`;

export default ContainerHamburger;
