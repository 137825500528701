import React from 'react';

import { StyleForm } from '../../../styles/components/StyleForm';

const RegisterForm = () => {
  return (
    <StyleForm>
      <input type="text" name="name" placeholder="Nombre completo" />
      <input type="Number" name="phone" placeholder="Número de Celular" />
      <input
        type="password"
        name="password"
        placeholder="Ingrese su contraseña"
      />
      <input
        type="password"
        name="confirmPassword"
        placeholder="Confirme su contraseña"
      />
      <input type="email" name="email" placeholder="Correo electronico" />
      <input
        type="text"
        name="nit"
        placeholder="Nit o CI para facturación(opcional)"
      />

      <button type="submit" className="form-register-btn">
        Registrarse
      </button>
    </StyleForm>
  );
};

export default RegisterForm;
