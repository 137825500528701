import {
  GET_PRODUCTS,
  ERROR_GET_PRODUCTS,
  SEARCH_PRODUCTS,
  ERROR_SEARCH_PRODUCTS,
  CLEAR_SEARCH,
  GET_DETAIL_PRODUCT,
  ERROR_GET_DETAIL_PRODUCT,
  CLEAR_ERRORS,
  GET_PRODUCUT_BY_SUPPLIER,
  ERROR_GET_PRODUCUT_BY_SUPPLIER,
  CLEAR_SUPPLIER,
  GET_PRODUCTS_BY_CATEGORY,
  ERROR_GET_PRODUCTS_BY_CATEGORY,
  GET_SUPPLIER,
  GET_YEARS,
  ERROR_GET_YEARS,
  GET_BRANDS,
  ERROR_GET_BRANDS,
  GET_MODELS,
  ERROR_GET_MODELS,
  CLEAR_BRANDS,
  CLEAR_MODELS,
  GET_BRANCH_OFFICES,
  ERROR_GET_BRANCH_OFFICES,
  GET_CATEGORY_BY_SUPPLIER,
  ERROR_GET_CATEGORY_BY_SUPPLIER,
  MORE_PRODUCTS
} from '../Types';

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, { type, payload }) => {
  switch (type) {
    case GET_PRODUCTS:
    case GET_PRODUCTS_BY_CATEGORY:
      return {
        ...state,
        products: payload
      };

    case MORE_PRODUCTS:
      return {
        ...state,
        products: payload
      };

    case GET_SUPPLIER:
      return {
        ...state,
        supplier: payload
      };

    case GET_PRODUCUT_BY_SUPPLIER:
      return {
        ...state,
        products: payload,
        isSupplier: true
      };

    case GET_CATEGORY_BY_SUPPLIER:
      return {
        ...state,
        categories: payload,
        isSupplier: true
      };

    case GET_YEARS:
      return {
        ...state,
        years: payload
      };

    case GET_BRANDS:
      return {
        ...state,
        brands: payload
      };

    case GET_MODELS:
      return {
        ...state,
        models: payload
      };

    case GET_BRANCH_OFFICES:
      return {
        ...state,
        branchOffices: payload
      };

    case ERROR_GET_PRODUCTS:
    case ERROR_GET_PRODUCUT_BY_SUPPLIER:
      return {
        ...state,
        products: [],
        isSupplier: false,
        errors: payload
      };

    case ERROR_GET_PRODUCTS_BY_CATEGORY:
      return {
        ...state,
        products: [],
        errors: payload
      };

    case ERROR_GET_CATEGORY_BY_SUPPLIER:
      return {
        ...state,
        categories: null,
        isSupplier: false,
        errors: payload
      };

    case ERROR_GET_YEARS:
      return {
        ...state,
        years: null
      };

    case ERROR_GET_BRANDS:
      return {
        ...state,
        brands: null
      };

    case ERROR_GET_MODELS:
      return {
        ...state,
        models: null
      };

    case ERROR_GET_BRANCH_OFFICES:
      return {
        ...state,
        errors: payload,
        branchOffices: null
      };

    case CLEAR_SUPPLIER:
      return {
        ...state,
        supplier: null,
        isSupplier: false,
        categories: null
      };

    case GET_DETAIL_PRODUCT:
      return {
        ...state,
        detailProduct: payload
      };

    case ERROR_GET_DETAIL_PRODUCT:
      return {
        ...state,
        detailProduct: [],
        errors: payload
      };

    case SEARCH_PRODUCTS:
      return {
        ...state,
        search: payload
      };

    case ERROR_SEARCH_PRODUCTS:
      return {
        ...state,
        search: null,
        errors: payload
      };

    case CLEAR_SEARCH:
      return {
        ...state,
        products: [],
        search: null
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null
      };

    case CLEAR_BRANDS:
      return {
        ...state,
        brands: null
      };

    case CLEAR_MODELS:
      return {
        ...state,
        models: null
      };

    default:
      return state;
  }
};
