import React from 'react';

import Featured from '../components/container/Featured';

const SearchProduct = ({ pageEnd, loading, nextPage }) => {
  return (
    <>
      <Featured pageEnd={pageEnd} loading={loading} nextPage={nextPage} />
    </>
  );
};

export default SearchProduct;
