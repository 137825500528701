import styled from 'styled-components';

export const DetailCardProduct = styled.div`
  background-color: var(--whiteColor);
  border-radius: 10px;
  padding: 1rem;
  color: var(--textColor);

  .alice-carousel__dots {
    margin: 1rem 0 0;
  }
  .alice-carousel__prev-btn {
    display: none;
    /* background-color: var(--textColorSombra);
    border-radius: 50%;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;

    top: calc(40% - 12.5px);
    left: 0.5rem;

    .alice-carousel__prev-btn-wrapper {
      margin: 0;
      position: absolute;
      top: -4px;
      left: 1px;
      p {
        margin: 0;
        font-size: 2rem;
        color: var(--textColor);
      }
    } */
  }

  .alice-carousel__next-btn {
    display: none;
    /* background-color: var(--textColorSombra);
    border-radius: 50%;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;

    top: calc(40% - 12.5px);
    right: 0.5rem;

    .alice-carousel__next-btn-wrapper {
      margin: 0;
      position: absolute;
      top: -4px;
      right: 1px;
      p {
        margin: 0;
        font-size: 2rem;
        color: var(--textColor);
      }
    } */
  }

  .product-img {
    border-radius: 1rem;
    text-align: center;
    img {
      margin: o auto;
      border-radius: 1rem;
    }
  }

  .title-card-details {
    font-family: var(--fontTitle);
    font-size: 1.4rem;
    margin: 0.7rem 0;
  }
  .description-card-details {
    margin: 0;
    font-family: var(--fontText);
    line-height: 1.5rem;
  }

  .specs-details {
    background-color: #f4f6f9;
    border-radius: 1rem;
    padding: 0.3rem 1rem 1.3rem;
    margin: 2rem 0;

    .specs-title {
      text-align: center;
      margin: 0;
      margin-top: 1rem;
      margin-bottom: 1rem;
      text-decoration: underline;
      font-family: var(--fontBold);
      font-size: 2rem;
    }

    .technical-specs {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      .technical-specs-key {
        background-color: var(--whiteColor);
        border-radius: 0.5rem;
        margin: 0;
        margin-top: 0.5rem;
        padding: 0.5rem 0;
        font-size: 1.3rem;
        width: 34%;
        text-align: center;
      }
      .technical-specs-value {
        background-color: var(--whiteColor);
        border-radius: 0.5rem;
        margin: 0;
        margin-top: 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 1.3rem;
        width: 64%;
      }

      a {
        background-color: var(--secondaryColorSombra);
        border-radius: 0.4rem;
        color: var(--textColor);
        padding: 0.5rem 1.5rem;
        text-decoration: none;
        font-size: 1.2rem;
        margin-bottom: 1rem;
      }
    }
  }

  .details-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .btn-branchOffices {
      background-color: var(--infoColor);
      border-radius: 0.5rem;
      color: var(--whiteColor);
      cursor: pointer;
      padding: 0.5rem 1rem;
      font-size: 1.2rem;
    }

    .btn-whatsapp {
      background-color: #20b075;
      border-radius: 0.5rem;
      color: var(--whiteColor);
      text-decoration: none;
      display: flex;
      flex-direction: row;
      padding: 0.5rem 1rem;

      span {
        margin-left: 0.5rem;
      }
    }

    .btn-buy {
      background-color: var(--primaryColor);
      border-radius: 0.5rem;
      color: var(--whiteColor);
      cursor: pointer;
      display: flex;
      flex-direction: row;
      padding: 0.5rem 1rem;

      span {
        margin-left: 0.5rem;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 2rem 3rem;

    .product-img {
      img {
        margin-bottom: 1rem;
        max-height: 30rem;
      }
    }

    .title-card-details {
      font-size: 2rem;
    }

    .description-card-details {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    .specs-details {
      margin-bottom: 2rem;
      .specs-title {
        font-size: 2.2rem;
        margin: 0;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
      }

      .technical-specs {
        .technical-specs-key {
          font-size: 1.5rem;
        }
        .technical-specs-value {
          font-size: 1.5rem;
        }
        a {
          font-size: 1.5rem;
        }
      }
    }

    .details-buttons {
      .btn-branchOffices {
        padding: 0.7rem 1.5rem;
        font-size: 1.5rem;
      }

      .btn-whatsapp {
        padding: 0.7rem 1.5rem;
        font-size: 1.5rem;

        span {
          margin-left: 0.5rem;
        }
      }

      .btn-buy {
        padding: 0.7rem 1.5rem;
        font-size: 1.5rem;

        span {
          margin-left: 0.5rem;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 2rem 3rem;

    .title-card-details {
      margin: 1.5rem 0;
    }
  }

  @media (min-width: 1200px) {
    padding: 3rem 6rem;
  }
`;

export const CardInfoDetail = styled.div`
  color: var(--textColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;

  .card-info-price {
    .info-price {
      margin: 0;
      font-size: 1.2rem;
    }

    .info-price span {
      font-size: 2rem;
      vertical-align: top;
      margin-left: 0.2rem;
    }
    a {
      background-color: var(--secondaryColorSombra);
      border-radius: 0.4rem;
      padding: 0.5rem 1rem;
      text-decoration: none;
      color: var(--textColor);
      font-size: 1.2rem;
    }
  }

  .info-distribuidor {
    background-color: #f4f6f9;
    border-radius: 0.5rem;
    color: var(--textColor);
    padding: 0.5rem 1rem;

    p {
      margin: 0;
      font-size: 1.2rem;
      text-align: right;
      font-family: var(--fontText);
    }
    h4 {
      margin: 0;
      font-size: 1.5rem;
      text-align: right;
    }
  }

  @media (min-width: 768px) {
    .card-info-price {
      a {
        font-size: 1.5rem;
      }
    }
    .info-distribuidor {
      p {
        font-size: 1.5rem;
      }
      h4 {
        font-size: 2rem;
      }
    }
  }
`;
