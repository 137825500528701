import React from 'react';

import { Link } from 'react-router-dom';

import Nav from '../../styles/components/StyleNav';

// import images
import hamburgerIcon from '../../assets/icons/menuIcon.svg';
import userImageBlue from '../../assets/icons/userBlue.svg';
import cartBlue from '../../assets/icons/cartBlue.svg';

const NavComponent = ({
  handleClick,
  clearSearch,
  getProducts,
  clearErrors,
  clearCategoryAndSupplier,
  handleSubCategory,
  handleNameCategory
}) => {
  return (
    <Nav>
      <div className="menu-hamburger">
        <img onClick={handleClick} src={hamburgerIcon} alt="hamburger menu" />
        <Link
          to="/"
          onClick={() => {
            clearSearch();
            clearErrors();
            clearCategoryAndSupplier();
            getProducts();
            handleSubCategory('');
            handleNameCategory('all');
          }}
        >
          <h1>Sabaneo</h1>
        </Link>
      </div>

      <div className="menu-icons">
        <div className="user-img">
          <Link to="/profile">
            <img src={userImageBlue} alt="user default" />
          </Link>
        </div>

        <div className="user-cart">
          <Link to="/cart">
            <img src={cartBlue} alt="cart" />
            <p>0</p>
          </Link>
        </div>
      </div>
    </Nav>
  );
};

export default NavComponent;
