import styled from 'styled-components';

export const Card = styled.div`
  background-color: var(--whiteColor);
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  padding: 1rem;
  box-shadow: 5px 5px 5px #e5e5e5;

  @media (min-width: 768px) {
    width: 49%;
    padding: 1.5rem;
  }

  @media (min-width: 1024px) {
    width: 32%;
    transition: all 0.3s linear;

    &:hover {
      transform: scale(1.05);
    }
  }

  @media (min-width: 1200px) {
    margin-top: 0;
  }
`;

export const CardImage = styled.div`
  text-align: center;
  border-radius: 1rem;
  margin: o auto;
  img {
    max-height: 14rem;
  }

  @media (min-width: 1200px) {
    img {
      max-height: 18rem;
    }
  }
`;

export const H3 = styled.h3`
  text-transform: uppercase;
  margin: 0.5rem 0;
  min-height: 4rem;

  a {
    text-decoration: none;
    color: var(--textColor);
    font-family: var(--fontBold);
    font-size: 1.1rem;
  }

  @media (min-width: 1200px) {
    font-size: 1.4rem;
  }
`;

export const CardStarts = styled.div`
  .card-start {
    color: ${(props) =>
      props.supplierId === 'usr-100008'
        ? 'var(--primaryColor)'
        : 'var(--secondaryColor)'};
    font-size: 1.5rem;
  }
`;

export const CardInfo = styled.div`
  color: var(--textColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;

  .card-info-price {
    .info-price {
      margin: 0;
      font-size: 1.2rem;
    }

    .info-price span {
      font-size: 2rem;
      vertical-align: top;
      margin-left: 0.2rem;
    }

    .card-info-login {
      background-color: ${(props) =>
        props.supplierId === 'usr-100008'
          ? 'var(--primaryColorSombra)'
          : 'var(--secondaryColorSombra)'};
      border-radius: 0.5rem;
      color: var(--textColor);
      cursor: pointer;
      padding: 0.5rem;
      font-size: 1.2rem;
    }
  }

  .info-distribuidor {
    background-color: #f4f6f9;
    border-radius: 0.5rem;
    color: var(--textColor);
    padding: 0.5rem 1rem;

    p {
      margin: 0;
      font-size: 1.2rem;
      text-align: right;
      font-family: var(--fontText);
    }
    h4 {
      margin: 0;
      font-size: 1.5rem;
      text-align: right;
    }
  }
`;

export const CardButtons = styled.div`
  color: var(--whiteColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;

  a {
    text-decoration: none;
    color: var(--whiteColor);
  }

  .button-info {
    background-color: var(--infoColor);
    border-radius: 0.5rem;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;

    span {
      margin-left: 0.5rem;
      margin-top: 0.1rem;
    }
  }
  .button-buy {
    background-color: var(--primaryColor);
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;

    span {
      margin-left: 0.5rem;
    }
  }
`;
