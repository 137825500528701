import React from 'react';
import { useLocation } from 'react-router-dom';

import imgPrueba from '../../assets/images/moto.png';
import addImg from '../../assets/icons/plusGreen.svg';
import trashImg from '../../assets/icons/trashOrange.svg';
import styled from 'styled-components';

const CartProduct = () => {
  const { pathname } = useLocation();
  let urlPath = pathname.split('/');
  urlPath = urlPath[1];
  return (
    <StyleCartProduct>
      <div className="cart-product-img">
        <img className="img-product" src={imgPrueba} alt="" />
      </div>
      <div className="detail-cart-product">
        <div className="name-cart-product">
          <p>nombre del producto agregando otros datos</p>
        </div>
        {urlPath !== 'profile' ? (
          <div className="cart-price">
            <div className="price">
              <h3>
                <span>US$</span>350
              </h3>
            </div>
            <div className="cart-buttons">
              <div className="trash-button">
                <img src={trashImg} alt="" />
              </div>
              <span>1</span>
              <div className="add-button">
                <img src={addImg} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <h3 className="cart-date">16-06-2022</h3>
        )}
      </div>
    </StyleCartProduct>
  );
};

const StyleCartProduct = styled.div`
  border-radius: 1rem;
  box-shadow: 3px 3px 3px #e5e5e5;
  display: flex;
  flex-direction: row;
  background-color: var(--whiteColor);
  padding: 1rem;
  margin-bottom: 1rem;

  .cart-product-img {
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .detail-cart-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: start;
    width: 70%;
    padding: 0.5rem;

    .name-cart-product {
      font-size: 1.3rem;
      margin-bottom: 1rem;
      p {
        margin: 0;
      }
    }

    .cart-price {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      .price {
        font-size: 1.7rem;
        h3 {
          margin: 0;
          span {
            position: relative;
            font-size: 1.1rem;
            top: -0.6rem;
          }
        }
      }
      .cart-buttons {
        border: 2px solid #f4f6f9;
        border-radius: 1rem;
        display: flex;
        flex-direction: row;
        box-shadow: 4px 4px 4px #e5e5e5;
        height: 2.5rem;

        .trash-button {
          background-color: #f4f6f9;
          cursor: pointer;
          margin: 0;
          padding: 0.3rem 0.8rem;

          img {
            height: 100%;
          }
        }

        span {
          font-size: 1.5rem;
          margin: 0.2rem 1rem;
        }

        .add-button {
          background-color: #f4f6f9;
          cursor: pointer;
          padding: 0.3rem 0.8rem;
          img {
            height: 100%;
          }
        }
      }
    }

    .cart-date {
      color: var(--textColorSombra);
      margin: 0;
      text-align: right;
    }
  }

  @media (min-width: 425px) {
    .detail-cart-product {
      padding: 0.5rem 1rem;
    }
  }

  @media (min-width: 768px) {
    .detail-cart-product {
      .cart-price {
        .price {
          font-size: 2.2rem;
          h3 {
            margin: 0;
            span {
              font-size: 1.3rem;
              top: -0.7rem;
            }
          }
        }

        .cart-buttons {
          height: 3rem;

          span {
            font-size: 1.8rem;
          }
        }
      }
    }
  }

  @media (min-width: 1024px) {
    margin-bottom: 1.5rem;
    .cart-product-img {
      .img-product {
        width: 75%;
      }
    }
  }
`;

export default CartProduct;
