import React, { useState, useContext, useEffect, useRef } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import ProductContext from '../context/productsContext/ProductContext';

import Layout from '../pages/Layout';
import Home from '../pages/Home';
import SearchProduct from '../pages/SearchProduct';
import DetailProducts from '../pages/DetailProducts';
import HomeSupplier from '../pages/HomeSupplier';
import Profile from '../pages/Profile';
import Cart from '../components/container/Cart';
import Register from '../pages/Register';
import Login from '../pages/Login';

import { SUPPLIERS } from '../components/utils';

const RoutesComponent = () => {
  const { moreProducts, supplier, products } = useContext(ProductContext);
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [products]);

  const nextPage = () => {
    setPage((prevPage) => prevPage + 1);
    console.log(page);
    moreProducts('all', page);
  };

  /* useEffect(() => {
    moreProducts('all', page);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]); */

  const pageEnd = useRef();
  //let countProduct = 50;

  /* useEffect(() => {
    if (!loading) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            countProduct += 50;
            nextPage();
            if (countProduct > products.length) {
              observer.unobserve(pageEnd.current);
            }
          }
        },
        { threshold: 1 }
      );
      observer.observe(pageEnd.current);
    }
  }, [loading, countProduct]); */

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route
            path="products"
            element={
              <SearchProduct
                pageEnd={pageEnd}
                loading={loading}
                nextPage={nextPage}
              />
            }
          />
          <Route path="product-detail/:id" element={<DetailProducts />} />

          {/* Rutas para los clientes */}
          {SUPPLIERS.map((route) => (
            <Route
              key={route.idUser}
              path={route.uri}
              element={<HomeSupplier />}
            >
              <Route index element={<SearchProduct />} />
              <Route path=":category" element={<SearchProduct />} />
              <Route path="product-detail/:id" element={<DetailProducts />} />
            </Route>
          ))}

          <Route path="cart" element={<Cart />} />
          <Route path="profile" element={<Profile />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
