import React from 'react';

import styled from 'styled-components';

import phoneImg from '../../assets/icons/phoneBlue.svg';
import whatsAppImg from '../../assets/icons/whatsAppBlue.svg';
import emailImg from '../../assets/icons/emailBlue.svg';

const CardBranchOffice = ({ branchOffice, handleOpenBranchOffice }) => {
  const { direccion, email, fax, telefono, place, nombre } = branchOffice;

  return (
    <StyleCardBrachOffice>
      <h2 className="title-card-branch-office">{nombre}</h2>
      <p className="direction-branch-office">{direccion}</p>
      <div onClick={handleOpenBranchOffice} className="close-branch-office">
        <h2>X</h2>
      </div>

      <div className="icons-branch-office">
        <a href="/#">
          <img src={whatsAppImg} alt="" />
          <span>{telefono}</span>
        </a>
      </div>

      <div className="icons-branch-office">
        <a href="/#">
          <img src={phoneImg} alt="" />
          <span>{fax}</span>
        </a>
      </div>

      <div className="icons-branch-office">
        <a href={`mailto:${email}`}>
          <img src={emailImg} alt={nombre} />
          <span>{email}</span>
        </a>
      </div>

      <div className="btn-gps-branch-office">
        <a href={place} target="_blank" rel="noopener noreferrer">
          Ubicación GPS
        </a>
      </div>
    </StyleCardBrachOffice>
  );
};

const StyleCardBrachOffice = styled.div`
  background-color: var(--whiteColor);
  border-radius: 1rem;
  padding: 1rem;
  margin-top: 1rem;

  .title-card-branch-office {
    margin: 0;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    text-decoration: underline;
    font-family: var(--fontTitle);
  }

  .direction-branch-office {
    font-size: 1.2rem;
  }

  .close-branch-office {
    position: absolute;
    background-color: var(--textColor);
    border-radius: 0 0.8rem 0;
    cursor: pointer;
    top: 0;
    right: 0;
    padding: 0.5rem 1rem;
    color: var(--whiteColor);

    h2 {
      margin: 0;
    }
  }

  .icons-branch-office {
    margin-bottom: 0.5rem;

    a {
      color: var(--textColor);
      text-decoration: none;
    }
    span {
      font-size: 1.3rem;
      margin-left: 0.5rem;
    }
  }

  .btn-gps-branch-office {
    margin: 1.5rem 0 0.5rem 0;

    a {
      background-color: var(--primaryColor);
      border-radius: 0.4rem;
      color: var(--whiteColor);
      padding: 0.5rem 1rem;
      text-decoration: none;
      font-size: 1.4rem;
    }
  }

  @media (min-width: 768px) {
    width: 31.8%;
    margin-right: 1rem;

    .close-branch-office {
      padding: 0.5rem 1.5rem;
    }
  }

  .icons-branch-office {
    span {
      font-size: 1.5rem;
    }
  }
`;

export default CardBranchOffice;
