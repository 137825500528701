import styled from 'styled-components';

const DetailContainer = styled.div`
  padding: 1rem;

  @media (min-width: 768px) {
    padding: 1.5rem 3rem;
  }

  @media (min-width: 1024px) {
    padding: 2rem 5rem;
  }

  @media (min-width: 1200px) {
    max-width: 1250px;
    padding: 0;
    margin: 2.5rem auto;
  }
`;

export default DetailContainer;
