import React from 'react';

import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import Supplier from '../pure/Supplier';
import { SUPPLIERS } from '../utils';

import styled from 'styled-components';

const responsive = {
  0: { items: 1 },
  767: { items: 2 },
  1023: { items: 3 },
  1200: { items: 4 }
};
const items = [
  <Supplier
    img={SUPPLIERS[0].image}
    link={`/${SUPPLIERS[0].uri}`}
    user={SUPPLIERS[0].idUser}
  />,
  <Supplier
    img={SUPPLIERS[1].image}
    link={`/${SUPPLIERS[1].uri}`}
    user={SUPPLIERS[1].idUser}
  />,
  <Supplier
    img={SUPPLIERS[2].image}
    link={`/${SUPPLIERS[2].uri}`}
    user={SUPPLIERS[2].idUser}
  />,
  <Supplier
    img={SUPPLIERS[3].image}
    link={`/${SUPPLIERS[3].uri}`}
    user={SUPPLIERS[3].idUser}
  />,
  <Supplier
    img={SUPPLIERS[4].image}
    link={`/${SUPPLIERS[4].uri}`}
    user={SUPPLIERS[4].idUser}
  />,
  <Supplier
    img={SUPPLIERS[5].image}
    link={`/${SUPPLIERS[5].uri}`}
    user={SUPPLIERS[5].idUser}
  />
];

const Suppliers = () => {
  return (
    <SuppliersContainer>
      <AliceCarousel
        mouseTracking
        items={items}
        responsive={responsive}
        controlsStrategy="alternate"
        autoPlay
        infinite
        autoPlayInterval={2500}
      />
    </SuppliersContainer>
  );
};

const SuppliersContainer = styled.div`
  padding: 1rem;

  .alice-carousel__dots {
    margin: 1rem 0 0;
  }
  .alice-carousel__prev-btn {
    display: none;
    /* background-color: var(--textColorSombra);
    border-radius: 50%;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;

    top: calc(40% - 12.5px);
    left: 0.5rem;

    .alice-carousel__prev-btn-wrapper {
      margin: 0;
      position: absolute;
      top: -4px;
      left: 1px;
      p {
        margin: 0;
        font-size: 2rem;
        color: var(--textColor);
      }
    } */
  }

  .alice-carousel__next-btn {
    display: none;
    /* background-color: var(--textColorSombra);
    border-radius: 50%;
    position: absolute;
    width: 2.5rem;
    height: 2.5rem;

    top: calc(40% - 12.5px);
    right: 0.5rem;

    .alice-carousel__next-btn-wrapper {
      margin: 0;
      position: absolute;
      top: -4px;
      right: 1px;
      p {
        margin: 0;
        font-size: 2rem;
        color: var(--textColor);
      }
    } */
  }

  @media (min-width: 768px) {
    padding: 2rem 3rem 1rem;
    .alice-carousel {
      margin-left: 0.4rem;
    }
  }

  @media (min-width: 1024px) {
    padding: 2rem 5rem 0;
  }

  @media (min-width: 1200px) {
    width: 1250px;
    padding: 2rem 0 0;
    margin: 0 auto;
  }
`;
export default Suppliers;
