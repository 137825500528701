import React, { useReducer, useMemo } from 'react';

import axios from 'axios';

import { SUPPLIERS } from '../../components/utils';
import { URL } from '../urls';

import {
  CLEAR_BRANDS,
  CLEAR_MODELS,
  CLEAR_ERRORS,
  CLEAR_SEARCH,
  CLEAR_SUPPLIER,
  ERROR_GET_BRANDS,
  ERROR_GET_CATEGORY_BY_SUPPLIER,
  ERROR_GET_DETAIL_PRODUCT,
  ERROR_GET_MODELS,
  ERROR_GET_PRODUCTS,
  ERROR_GET_PRODUCUT_BY_SUPPLIER,
  ERROR_GET_YEARS,
  ERROR_SEARCH_PRODUCTS,
  GET_BRANDS,
  GET_DETAIL_PRODUCT,
  GET_MODELS,
  GET_PRODUCTS,
  GET_PRODUCTS_BY_CATEGORY,
  GET_PRODUCUT_BY_SUPPLIER,
  GET_SUPPLIER,
  GET_YEARS,
  SEARCH_PRODUCTS,
  GET_BRANCH_OFFICES,
  ERROR_GET_BRANCH_OFFICES,
  GET_CATEGORY_BY_SUPPLIER,
  MORE_PRODUCTS,
  ERROR_MORE_PRODUCTS
} from '../Types';

import ProductContext from './ProductContext';
import ProductReducer from './ProductReducer';

const ProductState = ({ children }) => {
  const initialState = {
    products: [],
    categories: null,
    isSupplier: false,
    search: null,
    detailProduct: [],
    errors: null,
    supplier: null,
    years: null,
    brands: null,
    models: null,
    branchOffices: null
  };

  const [state, dispatch] = useReducer(ProductReducer, initialState);

  const getProducts = async () => {
    try {
      const result = await axios.get(URL.GET_PRODUCTS);
      dispatch({
        type: GET_PRODUCTS,
        payload: result.data.resultado
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_PRODUCTS,
        payload: err
      });
    }
  };

  const moreProducts = async (user, page) => {
    try {
      let uri =
        'https://sabaneo.com/php/dao/SelkisCON.php?function=buscar&q=&p=all&cat=Todos&pagina=' +
        page;
      console.log(uri);
      const result = await axios.get(uri);
      console.log(result.data.resultado);
      dispatch({
        type: MORE_PRODUCTS,
        payload: result.data.resultado
      });
    } catch (err) {
      dispatch({
        type: ERROR_MORE_PRODUCTS,
        payload: err
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getProductsBySupplier = async (idSupplier) => {
    try {
      // url para proveedores
      let url = URL.GET_PRODUCTS_BY_SUPPLIER + idSupplier;

      // url especial para titi
      if (idSupplier === 'usr-100008')
        url = URL.GET_PRODUCTS_BY_TITI + idSupplier;

      // hacemos la peticion
      const result = await axios.get(url);

      // revisamos que tenga productos la peticion
      if (result.data.resultado === null)
        throw new Error('No se encontraron productos para este proveedor.');

      dispatch({
        type: GET_PRODUCUT_BY_SUPPLIER,
        payload: result.data.resultado
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_PRODUCUT_BY_SUPPLIER,
        payload: err.message
      });
    }
  };

  const getDetailProduct = async (id) => {
    try {
      const urlProduct = `${URL.GET_PRODUCT}${id}`;
      const result = await axios.get(urlProduct);

      // creamos un nuevo producto con los datos que necesitamos
      const newProduct = {};
      // copiamos los datos del array para convertirlo en un objeto
      const product = result.data.producto;
      const features = [...result.data.car].filter((el, i) => i > 0);
      const images = [...result.data.imagen].filter((el, i) => i > 0);
      const catalogs = [...result.data.catalogo].filter((el, i) => i > 0);

      // asignamos el producto
      newProduct.product = product;

      // verificamos que caracteristicas tenga valores
      if (features.length !== 0) {
        const newFeature = [];
        features.forEach((item) => {
          let newItem = {};
          newItem.name = item[0];
          newItem.value = item[1];
          newFeature.push(newItem);
        });
        newProduct.features = newFeature;
      }

      // verificamos que images tenga valores
      if (images.length !== 0) {
        const newImages = [];
        images.forEach((item) => {
          let newItem = {};
          newItem.idImg = item[0];
          newItem.link = item[1];
          newImages.push(newItem);
        });
        newProduct.images = newImages;
      }

      // verificamos que catalogo tenga valores
      if (catalogs.length !== 0) {
        const newCatalogs = [];
        images.forEach((item) => {
          let newItem = {};
          newItem.idCatalogue = item[0];
          newItem.link = item[1];
          newCatalogs.push(newItem);
        });
        newProduct.catalogs = newCatalogs;
      }

      dispatch({
        type: GET_DETAIL_PRODUCT,
        payload: newProduct
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_DETAIL_PRODUCT,
        payload: err
      });
    }
  };

  const getCategoryBySupplier = async (idSupplier) => {
    try {
      const url = `${URL.GET_CATEGORY_BY_SUPPLIER}${idSupplier}`;
      const result = await axios.get(url);
      const categories = [];

      // verificamos que tenga categorias
      if (result.data.resultado === null)
        throw new Error('No se encontraron categorias para este proveedor.');

      const newResult = [...result.data.resultado].filter((el, i) => i > 0);

      if (newResult.length !== 0) {
        newResult.forEach((el) => {
          let newItem = {};
          newItem.idSubCategory = el[0];
          newItem.category = el[1];
          newItem.amount = el[2];
          categories.push(newItem);
        });
      }

      dispatch({
        type: GET_CATEGORY_BY_SUPPLIER,
        payload: categories
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_CATEGORY_BY_SUPPLIER,
        payload: err
      });
    }
  };

  const getBranchOffices = async (idUser) => {
    try {
      const result = await axios.get(`${URL.GET_BRANCH_OFFICES}${idUser}`);
      dispatch({
        type: GET_BRANCH_OFFICES,
        payload: result.data.resultado
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_BRANCH_OFFICES,
        payload: err
      });
    }
  };

  const clearCategoryAndSupplier = () => {
    dispatch({
      type: CLEAR_SUPPLIER
    });
  };

  const searchProducts = async (
    idUser,
    idSubCategory,
    query,
    internal,
    external,
    height,
    year,
    brand,
    model,
    page
  ) => {
    try {
      let url = `${URL.SEARCH_PRODUCTS}&p=${idUser}&cat=${idSubCategory}&q=${query}&pagina=${page}`;
      if (idUser === 'usr-100008') {
        url = `${URL.SEARCH_PRODUCTS}&p=${idUser}&cat=${idSubCategory}&q=${query}&car-1=${internal}&car-2=${external}&car-3=${height}&ano=${year}&marca=${brand}&modelo=${model}&pagina=${page}`;
      }
      console.log(internal, external.height, idSubCategory);
      console.log(url);
      const result = await axios.get(url);
      if (result.data.resultado === null) {
        throw new Error('No encontramos coincidencias, vuelve a intentarlo');
      }
      dispatch({
        type: SEARCH_PRODUCTS,
        payload: result.data.resultado
      });
    } catch (err) {
      dispatch({
        type: ERROR_SEARCH_PRODUCTS,
        payload: err.message
      });
    }
  };

  const searchProductsByCategory = async (user, subCategory) => {
    try {
      let url = `${URL.SEARCH_BY_CATEGORY}${user}&cat=${subCategory}`;

      // url especial para titi
      if (user === 'usr-100008')
        url = `${process.env.REACT_APP_SEARCH_BY_CATEGORY_TITI}${user}&cat=${subCategory}`;
      const result = await axios.get(url);

      if (result.data.resultado === null) {
        throw new Error(
          'No encontramos productos para esta categoria, vuelve a intentarlo'
        );
      }

      dispatch({
        type: GET_PRODUCTS_BY_CATEGORY,
        payload: result.data.resultado
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_CATEGORY_BY_SUPPLIER,
        payload: err.message
      });
    }
  };

  const clearSearch = () => {
    dispatch({
      type: CLEAR_SEARCH
    });
  };

  const clearErrors = () => {
    dispatch({
      type: CLEAR_ERRORS
    });
  };

  const getImage = (image) => {
    return `${URL.GET_IMAGE}${image}`;
  };

  const getWhatsapp = (phone, companyName, urlProduct) => {
    let apiWhatsapp = 'https://api.whatsapp.com/send?';
    const text = `Hola ${companyName}, me gustaria tener mas información sobre este producto.`;
    const host = 'https://sabaneo-dep.vercel.app'; //http://localhost:3000';

    return `${apiWhatsapp}phone=591${phone}&text=${text} ${host}${urlProduct}`;
  };

  const getSupplier = (idSupplier) => {
    const supplier = SUPPLIERS.filter((sup) => sup.idUser === idSupplier);
    dispatch({
      type: GET_SUPPLIER,
      payload: supplier
    });
  };

  const getYears = async () => {
    try {
      const result = await axios.get(URL.GET_YEARS);

      if (result.data.resulta === null)
        throw new Error('No se encontraron resultados, vuelve a intentarlo');

      const years = [];
      const newResult = [...result.data.resultado].filter((el, i) => i > 0);
      newResult.forEach((el) => {
        let newItem = {};
        newItem.value = el[0];
        years.push(newItem);
      });

      dispatch({
        type: GET_YEARS,
        payload: years
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_YEARS,
        payload: err
      });
    }
  };

  const getBrands = async (year) => {
    try {
      const result = await axios.get(`${URL.GET_BRANDS}${year}`);

      if (result.data.resultado === null)
        throw new Error('No se encontraron resultados, vuelve a intentarlo');

      const brands = [];
      const newResult = [...result.data.resultado].filter((el, i) => i > 0);
      newResult.forEach((el) => {
        let newItem = {};
        newItem.brand = el[0];
        brands.push(newItem);
      });

      dispatch({
        type: GET_BRANDS,
        payload: brands
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_BRANDS,
        payload: err
      });
    }
  };

  const getModels = async (year, brand) => {
    try {
      const result = await axios.get(`${URL.GET_MODELS}${year}&marca=${brand}`);

      if (result.data.resultado === null)
        throw new Error('No se encontraron resultados, vuelve a intentarlo');

      const newResult = [...result.data.resultado].filter((el, i) => i > 0);
      const models = [];

      newResult.forEach((el) => {
        let newItem = {};
        newItem.model = el[0];
        models.push(newItem);
      });

      dispatch({
        type: GET_MODELS,
        payload: models
      });
    } catch (err) {
      dispatch({
        type: ERROR_GET_MODELS,
        payload: err
      });
    }
  };

  const clearBrands = () => {
    dispatch({
      type: CLEAR_BRANDS
    });
  };

  const clearModels = () => {
    dispatch({
      type: CLEAR_MODELS
    });
  };

  return (
    <ProductContext.Provider
      value={useMemo(
        () => ({
          products: state.products,
          categories: state.categories,
          supplier: state.supplier,
          isSupplier: state.isSupplier,
          search: state.search,
          detailProduct: state.detailProduct,
          errors: state.errors,
          years: state.years,
          brands: state.brands,
          models: state.models,
          branchOffices: state.branchOffices,
          getProducts,
          getSupplier,
          getProductsBySupplier,
          getDetail: getDetailProduct,
          getImage,
          getWhatsapp,
          getBranchOffices,
          searchProducts,
          searchProductsByCategory,
          clearSearch,
          clearErrors,
          clearCategoryAndSupplier,
          getYears,
          getBrands,
          getModels,
          clearBrands,
          clearModels,
          getCategoryBySupplier,
          moreProducts
        }),
        [
          state.products,
          state.categories,
          state.supplier,
          state.isSupplier,
          state.search,
          state.detailProduct,
          state.errors,
          state.years,
          state.brands,
          state.models,
          state.branchOffices
        ]
      )}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductState;
