export const GET_PRODUCTS = 'GET_PRODUCTS';
export const ERROR_GET_PRODUCTS = 'ERROR_GET_PRODUCTS';
export const MORE_PRODUCTS = 'MORE_PRODUCTS';
export const ERROR_MORE_PRODUCTS = 'ERROR_MORE_PRODUCTS';
export const GET_DETAIL_PRODUCT = 'GET_DETAIL_PRODUCT';
export const ERROR_GET_DETAIL_PRODUCT = 'ERROR_GET_DETAIL_PRODUCT';
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS';
export const ERROR_SEARCH_PRODUCTS = 'ERROR_SEARCH_PRODUCTS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_PRODUCUT_BY_SUPPLIER = 'GET_PRODUCT_BY_SUPPLIER';
export const ERROR_GET_PRODUCUT_BY_SUPPLIER = 'ERROR_GET_PRODUCT_BY_SUPPLIER';
export const GET_CATEGORY_BY_SUPPLIER = 'GET_CATEGORY_BYSUPPLIER';
export const ERROR_GET_CATEGORY_BY_SUPPLIER = 'ERROR_GET_CATEGORY_BYSUPPLIER';
export const CLEAR_SUPPLIER = 'CLEAR_SUPPLIER';
export const GET_PRODUCTS_BY_CATEGORY = 'GET_PRODUCTS_BY_CATEGORY';
export const ERROR_GET_PRODUCTS_BY_CATEGORY = 'ERROR_GET_PRODUCTS_BY_CATEGORY';

export const GET_SUPPLIER = 'GET_SUPPLIER';
export const GET_YEARS = 'GET_YEARS';
export const ERROR_GET_YEARS = 'ERROR_GET_YEARS';
export const GET_BRANDS = 'GET_BRANDS';
export const ERROR_GET_BRANDS = 'ERROR_GET_BRANDS';
export const GET_MODELS = 'GET_MODELS';
export const ERROR_GET_MODELS = 'ERROR_GET_MODELS';
export const CLEAR_BRANDS = 'CLEAR_BRANDS';
export const CLEAR_MODELS = 'CLEAR_MODELS';
export const GET_BRANCH_OFFICES = 'GET_BRANCH_OFFICES';
export const ERROR_GET_BRANCH_OFFICES = 'ERROR_GET_BRANCH_OFFICES';
