import React from 'react';
import AliceCarousel from 'react-alice-carousel';
import styled from 'styled-components';
import CardRecommended from '../pure/CardRecommended';

import CartProduct from '../pure/CartProduct';

const responsive = {
  0: { items: 2 },
  767: { items: 3 },
  1023: { items: 5 },
  1200: { items: 6 }
};

const items = [
  <CardRecommended />,
  <CardRecommended />,
  <CardRecommended />,
  <CardRecommended />,
  <CardRecommended />,
  <CardRecommended />
];

const Cart = () => {
  return (
    <StyleCartContainer>
      <h2 className="title-cart">Mis Compras</h2>
      <div className="container-cart">
        <div className="container-cart-products">
          <CartProduct />
          <CartProduct />
        </div>
        <div className="container-subtotal">
          <div className="subtotal-cart">
            <p>
              Total <span>US$</span>700
            </p>
          </div>
          <button className="cart-button">Procesar pago</button>
        </div>
      </div>
      <h3 className="recommended-cart-title">Recomendados para ti</h3>

      <AliceCarousel items={items} responsive={responsive} infinite />
    </StyleCartContainer>
  );
};

const StyleCartContainer = styled.div`
  color: var(--textColor);
  padding: 1rem;
  text-align: center;

  .title-cart {
    font-size: 2rem;
    font-family: var(--fontTitle);
    margin: 0;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .subtotal-cart {
    text-align: end;
    font-size: 1.8rem;

    span {
      position: relative;
      top: -0.4rem;
      font-size: 1.2rem;
      margin-left: 1rem;
      margin-right: 0.2rem;
    }
  }

  .cart-button {
    background-color: var(--secondaryColor);
    border: none;
    border-radius: 0.5rem;
    color: var(--whiteColor);
    cursor: pointer;
    padding: 0.5rem 0 0.8rem 0;
    outline: none;
    width: 80%;
    font-size: 2.2rem;
  }

  .recommended-cart-title {
    text-align: start;
    font-size: 1.5rem;
    text-decoration: underline;
  }

  /* style carousel */
  .alice-carousel__dots {
    display: none;
  }
  .alice-carousel__prev-btn {
    /* display: none; */
    background-color: var(--textColorSombra);
    border-radius: 50%;
    position: absolute;
    width: 3rem;
    height: 3rem;

    top: calc(50% - 15px);
    left: 0.5rem;

    .alice-carousel__prev-btn-wrapper {
      margin: 0;
      position: absolute;
      top: -2px;
      left: 4px;
      p {
        margin: 0;
        font-size: 2rem;
        color: var(--textColorSombra);
      }
    }
  }

  .alice-carousel__next-btn {
    /* display: none; */
    background-color: var(--textColorSombra);
    border-radius: 50%;
    position: absolute;
    width: 3rem;
    height: 3rem;

    top: calc(50% - 15px);
    right: 0.5rem;

    .alice-carousel__next-btn-wrapper {
      margin: 0;
      position: absolute;
      top: -2px;
      right: 3px;
      p {
        margin: 0;
        font-size: 2rem;
        color: var(--textColorSombra);
      }
    }
  }

  @media (min-width: 425px) {
    padding: 1.5rem;
    .recommended-cart-title {
      font-size: 1.8rem;
    }

    .subtotal-cart {
      font-size: 2rem;

      span {
        top: -0.6rem;
        font-size: 1.2rem;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 1.5rem 3rem;
    .container-cart {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .container-cart-products {
      width: 60%;
    }
    .container-subtotal {
      width: 40%;
      text-align: right;

      .subtotal-cart {
        font-size: 2.2rem;

        span {
          top: -0.5rem;
          font-size: 1.4rem;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    padding: 2rem 5rem;

    .title-cart {
      font-size: 3rem;
    }

    .container-cart-products {
      width: 50%;
    }

    .recommended-cart-title {
      font-size: 2.5rem;
    }
  }

  @media (min-width: 1200px) {
    padding: 0;
    max-width: 1250px;
    margin: 2rem auto;
  }
`;

export default Cart;
