import React from 'react';
import { Link } from 'react-router-dom';

import RegisterForm from '../pure/forms/RegisterForm';

import {
  StyleImageForm,
  StyleContainerForm
} from '../../styles/components/StyleForm';

const RegisterUser = () => {
  return (
    <StyleImageForm>
      <StyleContainerForm>
        <div className="container-form">
          <h2 className="form-title">Sabaneo</h2>

          <RegisterForm />

          <p className="form-link-login">
            ¿Ya estás registrado? <Link to="/login">Iniciar Sesión</Link>
          </p>
          <p className="form-info">
            Al registrarse en nuestra plataforma Ud. podra comprar y/o solicitar
            cotizacion de todas las empresas que lo esten usando.
          </p>
        </div>
      </StyleContainerForm>
    </StyleImageForm>
  );
};

export default RegisterUser;
