import React from 'react';
import { createSearchParams, useNavigate } from 'react-router-dom';

import {
  SupplierHamburger,
  TitleCategory,
  LinksHamburgerClient
} from '../../styles/components/StyleNavHamburger';

import listIcon from '../../assets/icons/listWhite.svg';

const NavHamburgerClient = ({
  handleClick,
  categories,
  supplier,
  handleSubCategory,
  searchProductsByCategory,
  getProductsBySupplier,
  clearSearch,
  clearErrors,
  handleNameCategory
}) => {
  const navigate = useNavigate();

  let supplierId = null;
  if (supplier !== null) supplierId = supplier[0].idUser;

  return (
    <>
      <SupplierHamburger supplierId={supplierId}>
        <div>
          <img src={supplier[0].image} alt={supplier[0].userName} />
        </div>
        <div className="close-btn" onClick={handleClick}>
          <span>X</span>
        </div>
      </SupplierHamburger>

      <TitleCategory>Categorías</TitleCategory>
      <LinksHamburgerClient
        onClick={() => {
          handleClick();
          clearSearch();
          clearErrors();
          handleSubCategory('Todos');
          getProductsBySupplier(supplierId);
          navigate(`/${supplier[0].uri}`);
        }}
      >
        <img src={listIcon} alt="lista de productos icon" />
        <span>Ver todos los productos</span>
      </LinksHamburgerClient>

      {categories !== null &&
        categories.map((el) => (
          <LinksHamburgerClient
            key={el.idSubCategory}
            onClick={() => {
              searchProductsByCategory(supplier[0].idUser, el.idSubCategory);
              handleSubCategory(el.idSubCategory);
              handleNameCategory(el.category);
              handleClick();
              clearSearch();
              clearErrors();
              navigate({
                pathname: `/${supplier[0].uri}/${el.category.toLowerCase()}`,
                search: '?' + createSearchParams({ cat: el.idSubCategory })
              });
            }}
          >
            <img src={listIcon} alt={el.category} />
            <span>
              {el.category}({el.amount})
            </span>
          </LinksHamburgerClient>
        ))}
    </>
  );
};
/* navigate({
        pathname: supplier !== null ? `${supplier[0].uri}` : '/products',
        search:
          '?' +
          createSearchParams({
            category: subCategory,
            query,
            car1: internal,
            car2: external,
            car3: height,
            year,
            brand,
            model
            //page
          })
      }); */

export default NavHamburgerClient;
