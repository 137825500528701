import styled from 'styled-components';
import SForm from '../../assets/icons/SForm.svg';

export const StyleImageForm = styled.div`
  background-image: url(${SForm});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;

  @media (min-width: 768px) {
    background-size: contain;
    background-position: center;
  }
`;

export const StyleContainerForm = styled.div`
  padding: 1rem;

  .container-form {
    border: 2px solid var(--textColorSombra);
    border-radius: 1rem;
    box-shadow: 4px 4px 4px var(--textColorSombra);
    color: var(--textColor);
    padding: 1rem;

    .form-title {
      margin: 0;
      font-size: 3rem;
      font-family: var(--fontTitle);
      margin-bottom: 1.2rem;
      text-align: center;
    }

    .form-link {
      color: var(--textColorSombra);
      font-size: 1.3rem;
      text-align: center;
      a {
        text-decoration: none;
        color: var(--primaryColor);
      }
    }

    .form-link-login {
      color: var(--textColorSombra);
      font-size: 1.3rem;
      text-align: center;
      a {
        text-decoration: none;
        color: var(--secondaryColor);
      }
    }
    .form-info {
      color: var(--textColorSombra);
      text-align: left;
      margin-left: 1rem;
      margin-top: 5rem;
    }
  }

  @media (min-width: 768px) {
    padding: 3rem;
    width: 70%;
    margin: 0 auto;

    .container-form {
      .form-title {
        font-size: 3.5rem;
        margin-top: 1.5rem;
        margin-bottom: 3rem;
      }
      .form-link {
        font-size: 1.6rem;
      }
      .form-info {
        font-size: 1.3rem;
      }
    }
  }

  @media (min-width: 1024px) {
    width: 50%;
    padding: 5rem;
  }
`;

export const StyleForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  input {
    border: none;
    border-radius: 0.5rem;
    color: var(--textColor);
    padding: 0.7rem 1rem;
    outline: none;
    margin-bottom: 1rem;
    font-size: 1.3rem;

    ::placeholder {
      color: var(--textColorSombra);
    }
  }
  .form-check {
    label {
      color: var(--textColorSombra);
      font-size: 1.2rem;
      margin-left: 0.5rem;
    }
  }
  .form-remenber {
    text-decoration: none;
    color: var(--primaryColor);
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }

  .form-login-btn {
    border: none;
    outline: none;
    background-color: var(--secondaryColor);
    border-radius: 0.5rem;
    color: var(--whiteColor);
    cursor: pointer;
    padding: 0.5rem;
    font-size: 1.7rem;
    width: 60%;
    margin: 0 auto;
  }

  .form-register-btn {
    border: none;
    outline: none;
    background-color: var(--primaryColor);
    border-radius: 0.5rem;
    color: var(--whiteColor);
    cursor: pointer;
    padding: 0.5rem;
    font-size: 1.7rem;
    width: 60%;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    padding: 0 2rem;
    input {
      margin-bottom: 2.5rem;
      padding: 1rem 1rem;
      font-size: 1.5rem;
    }
    .form-remenber {
      font-size: 1.5rem;
    }
    .form-login-btn {
      width: 50%;
      margin-top: 1.5rem;
    }
  }

  @media (min-width: 1024px) {
    .form-check {
      label {
        font-size: 1.5rem;
      }
    }
  }
`;
