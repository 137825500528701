import styled from 'styled-components';

const StyleNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 3rem;

  & a {
    text-decoration: none;
  }

  .menu-hamburger {
    display: flex;
    flex-direction: row;

    h1 {
      color: var(--whiteColor);
      margin-left: 1rem;
      margin-top: 1.3rem;
      font-size: 2.5rem;
      font-family: var(--fontTitle);
    }
    img {
      width: 2.7rem;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .menu-icons {
    display: flex;
    flex-direction: row;
    align-items: center;

    .user-img {
      margin-right: 1rem;
    }

    .user-cart {
      color: var(--whiteColor);
      position: relative;
      bottom: 0.2rem;
      img {
        width: 3.7rem;
      }
      p {
        position: absolute;
        background-color: var(--whiteColor);
        border-radius: 50%;
        color: var(--textColor);
        width: 1.2rem;
        height: 1.2rem;
        text-align: center;
        top: -0.1rem;
        left: 1rem;
      }
    }
  }

  @media (min-width: 1024px) {
    .menu-hamburger {
      img {
        width: 3.2rem;
      }
    }

    .menu-icons {
      .user-cart {
        img {
          width: 4.5rem;
        }
        p {
          font-size: 1.31rem;
          width: 1.5rem;
          height: 1.5rem;
          top: -0.2rem;
          left: 1.2rem;
        }
      }

      .user-img {
        margin-right: 1.2rem;
        img {
          width: 2.6rem;
        }
      }
    }
  }

  @media (min-width: 1200px) {
    max-width: 1250px;
    margin: 0 auto;
  }
`;

export default StyleNav;
